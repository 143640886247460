import isEmpty from 'lodash/isEmpty'

/**
 * Creates a list of mutations based on the action names and their values
 * For generic code purposes actions and mutations must follow a generic naming convention
 *
 * - action-name => SET_<ACTION-NAME>
 *               => INCREASE_PAGE_<ACTION-NAME>
 * e.g.
 * - latest      => SET_LATEST
 *               => INCREASE_PAGE_LATEST
 * @param {Object} actions key value pair of store-action names and their value
 * @returns {Object} key value pair of store-mutations
 */
export const getMutationNamesFromActions = (actions) => {
  const mutations = []
  Object.entries(actions).forEach(([key, value]) => {
    mutations[value] = `SET_${key.split(/_(.+)/)[1]}`
    mutations[value + '_page'] = `INCREASE_PAGE_${key.split(/_(.+)/)[1]}`
  })
  return mutations
}

/**
 * Generic function to increase pagination page
 * @param action
 * @param commit
 */
export const increasePaginationPage = (action, commit) => {
  commit('INCREASE_PAGE_' + action.toUpperCase())
}

/**
 * Function that returns true if there are more items available on the server based on the offset and limit in the meta-data.
 * @param {Object} items Object containing previously queried items and meta-data
 * @returns {Boolean} true if there are more items on the server that can be retrieved
 */
export const hasMoreItemsAvailable = items => items?.meta && items?.meta.total > items?.meta.offset + items?.meta.limit

/**
 *
 * @param {Object} items the items that are in the store
 * @param {Object} getRequestParam the requestParams with the topic (to filter to store per topic) and the offset/limit
 * @returns {Boolean} true if the number of items of the specified topic needed are already in the store and false otherwise
 */
export const hasInStoreForTopic = (items = [], requestParams = {}) => {
  const itemsInStore = filterItemsByTopic(items, requestParams.topic)
  const itemsNeeded = requestParams.offset + requestParams.limit
  return itemsNeeded <= itemsInStore?.length
}

/**
 * Function that returns the query params to get a list from prepr. The limit and offset are set based on given params or set to default.
 * @param {Object} items the current list of items in the store if present
 * @param {Object} params The prepr-channel, offset and limit of the query if given
 * @param {Object} channels The prepr-channels available for this request
 * @param {Object} limit the default limit to use if no limit is given in params
 * @param {string} domain the domain to retrieve the data from
 * @returns {Object} params with the resulting prepr channel, offset and limit for the query.
 */
export const getRequestParams = (items, params, channels, limit, domain) => {
  if (params.loadMore && items?.length) { // Setting the offset and limit if it is a loadMore request
    params.offset = items.length
    params.limit = params.offset + limit
  }
  return {
    channel: channels[`PREPR_CHANNEL_${params.action.toUpperCase()}`],
    offset: params?.offset ? params.offset : 0,
    limit: params?.limit ? params.limit : limit,
    domain,
  }
}

/**
 * Filters the items in the state based on their topic
 * @param {Object} state the state that holds the array of items that need to be filtered
 * @param {String} topic the topic by which the items need to be filtered
 * @returns {Object} The array of items from the state that have the same topic as given in the params or [] if no items or topic is given
 */
export const filterItemsByTopic = (items = [], topic = '') => {
  return items.filter(item => item.topic.slug === topic)
}

/**
 * Filters the items in the state based on their model
 * @param {Object} state the state that holds the array of items that need to be filtered
 * @param {String} model the model-id by which the items need to be filtered
 * @returns {Object} The array of items from the that have the same model-id as given in the params
 */
// TODO Implement when api returns model-type
export const filterStateByModel = (items = [], model) => {
  if (!Array.isArray(items)) {
    return undefined
  }
  if (model) {
    return items.filter(item => item.reference.model === model)
  } else {
    return items
  }
}

/**
 * Maps the items of a payload to the right structure and adds the payload to the current payload in the state
 * or just the payload if the current state is empty
 * @param {Object} state the state that holds the current payload (empty if not yet filled)
 * @param {Object} payload the items and meta-data to be stored in the state
 * @returns {Object} payload to be stored in the state with mapped items.
 */
export const getMutation = (state, payload) => {
  if (isEmpty(state)) {
    return payload
  } else {
    return {
      meta: payload.meta,
      items: [...new Set([
        ...state.items,
        ...payload.items,
      ])],
    }
  }
}
