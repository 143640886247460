import objectMapper from 'object-mapper'
import { communityCompact } from './community'
import { personCompact } from './person'
import { programCompact } from './program'
import { tagCompact } from './tag'
import { topicCompact } from './topic'

const podcastPlatformToLogo = (name) => {
  if (!name) {
    return null
  }
  const platformName = name.toUpperCase()
  switch (platformName) {
    case 'APPLE PODCASTS':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/7f9144da-d85f-4426-874e-5b48ec8eff24.jpg'
    case 'GOOGLE PODCASTS':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/ae054b7b-5fd9-46dd-886d-7a39403c19a0.jpg'
    case 'THE PODCAST APP':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/a910916e-ea82-4663-a4ff-5f494d5fddd7.png'
    case 'SOUNDCLOUD':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/64e57104-61b4-4858-9ea4-b0f50b73234a.jpg'
    case 'SPOTIFY':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/dcfb1080-4cd8-4e5f-ae7d-b13a7da0c3ad.jpg'
    case 'RSS':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/8e5fc5c3-8e7b-4e01-8908-f929d1ac67b4.png'
    case 'NPO':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/14239559-0e1b-4c81-8092-4fdf820189af.png'
    case 'STITCHER':
      return 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/775abad3-4bfa-4894-ba12-407e35049a7b.png'
  }
  return name
}

export const podcastCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.logo.items[0].cdn_files.items[0].url': 'logo',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.community.items[0]': {
      key: 'community',
      transform: p => p && communityCompact(p),
    },
  }

  return objectMapper(data, config)
}

export const podcast = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.hero_video.items[0].cdn_files.items[0].url': 'heroVideo',
    'items.nl-NL.hero_video.items[0].cover.cdn_files.items[0].url': 'heroVideoStill',
    'items.nl-NL.logo.items[0].cdn_files.items[0].url': 'logo',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.content.items[]': 'content[]',
    'items.nl-NL.extra_content.items[]': 'extraContent[]',
    'items.nl-NL.genre.body': 'genreName',
    'items.nl-NL.genre.id': 'genreId',
    'items.nl-NL.podcast_urls.items[].items.url.body': 'platforms[].url',
    'items.nl-NL.podcast_urls.items[].items.source.body': [
      'platforms[].name',
      {
        key: 'platforms[].logo',
        transform: podcastPlatformToLogo,
      },
    ],
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.presenter.items[]': {
      key: 'presenters',
      transform: (presenters) => {
        if (!presenters || !presenters.length) {
          return null
        }
        return presenters.map(p => personCompact(p))
      },
    },
    'items.nl-NL.program.items[0]': {
      key: 'program',
      transform: p => p && programCompact(p),
    },
    'items.nl-NL.community.items[0]': {
      key: 'community',
      transform: p => p && communityCompact(p),
    },
    'items.nl-NL.tags.items[]': {
      key: 'tags',
      transform: (tags) => {
        if (!tags || !tags.length) {
          return null
        }
        return tags.map(p => tagCompact(p))
      },
    },
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
  }

  return objectMapper(data, config)
}
