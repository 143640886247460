/* eslint-disable quote-props */
import objectMapper from 'object-mapper'
import { personCompact } from './person'
import { programCompact } from './program'
import { tagCompact } from './tag'
import { topicCompact } from './topic'

export const pressReleaseCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.type.body': 'type',
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
  }

  return objectMapper(data, config)
}

export const pressRelease = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.featured_video.items[]': 'featuredVideo[]',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.content.items[]': 'content[]',
    'items.nl-NL.downloads.items': {
      key: 'downloads',
      transform: items => (items && {
        images: items.filter(d => d.label === 'Photo'),
        others: items.filter(d => d.label !== 'Photo'),
      }),
    },
    'items.nl-NL.type.body': 'type',
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
    'items.nl-NL.program.items[0]': {
      key: 'program',
      transform: p => p && programCompact(p),
    },
    'items.nl-NL.tags.items[]': {
      key: 'tags',
      transform: (tags) => {
        if (!tags || !tags.length) {
          return null
        }
        return tags.map(p => tagCompact(p))
      },
    },
    'items.nl-NL.contact.items[]': {
      key: 'contacts',
      transform: (publications) => {
        if (!publications || !publications.length) {
          return null
        }
        return publications.map(p => personCompact(p))
      },
    },
    'items.nl-NL.presenter.items[]': {
      key: 'presenters',
      transform: (publications) => {
        if (!publications || !publications.length) {
          return null
        }
        return publications.map(p => personCompact(p))
      },
    },
  }

  return objectMapper(data, config)
}
