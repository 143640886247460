import { mapGetters } from 'vuex'

const eoRendererMixin = {
  computed: {
    ...mapGetters('cookieConsent', {
      cookiePermissions: 'permissions',
    }),
  },
}

export default eoRendererMixin
