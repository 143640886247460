
export default {
  data () {
    return {
      isGyroscopeRunning: false,
      gyroRotation: 0,
    }
  },
  computed: {
    isGyroscopeAvailable () {
      return process.client && window.DeviceOrientationEvent && 'ontouchstart' in window
    },
    isGyroscopeAllowed () {
      return process.client && (!DeviceMotionEvent || typeof DeviceMotionEvent.requestPermission !== "function")
    },
  },
  watch: {
    isGyroscopeAllowed: {
      handler(isGyroscopeAllowed) {
        if (isGyroscopeAllowed) {
          this.initializeGyroscope()
        }
      },
      immediate: true,
    },
  },
  mounted () {
    if (DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === "function") {
      document.addEventListener("click", this.requestGyroscope)
    }
  },
  beforeDestroy () {
    this.cleanupGyroscope()
  },
  methods: {
    async requestGyroscope() {
      // Request permission for iOS 13+ devices
      if (process.client && !this.isGyroscopeAllowed) {
        try {
          const response = await DeviceMotionEvent.requestPermission()
          if (response === 'granted') {
            this.initializeGyroscope()
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    initializeGyroscope () {
      if (process.client && !this.isGyroscopeRunning && this.isGyroscopeAvailable) {
        window.addEventListener("deviceorientation", this.onTilt)
        this.isGyroscopeRunning = true
      }
    },
    cleanupGyroscope () {
      if (process.client && this.isGyroscopeRunning) {
        window.removeEventListener("deviceorientation", this.onTilt)
        this.isGyroscopeRunning = false
        document.removeEventListener("click", this.requestGyroscope)
      }
    },
    onTilt(event) {
      requestAnimationFrame(() => {
        const { alpha, beta, gamma } = event
        this.gyroRotation = this.getRotation(alpha, beta, gamma)
      })
    },
    getRotation (alpha, beta, gamma) {
      if (gamma === 0) return beta > 0 ? 0 : 180
      if (beta === 0) return gamma > 0 ? -90 : 90

      // Calculate the perceived gravity direction
      let angle = Math.atan2(gamma, beta) * (180 / Math.PI)

      // Ensure the angle is within -180 to 180 range
      if (angle > 180) {
        angle -= 360
      } else if (angle < -180) {
        angle += 360
      }

      return -angle
    },
  },
}
