import objectMapper from 'object-mapper'
// import { topicCompact } from './topic'

export const teaserCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'image_url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'teaser': 'title',
    'url': 'url',
    'reference': 'reference',
    // 'metadata.topic.items[0]': {
    //   key: 'topic',
    //   transform: p => p && topicCompact(p),
    // },
    'metadata.topic.id': 'topic.id',
    'metadata.topic.name': 'topic.name',
    'metadata.topic.slug': 'topic.slug',
  }

  return objectMapper(data, config)
}
