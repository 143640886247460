import pianoAnalyticsStore from './piano-analytics.store'

export default async ({ store }) => {
  /**
   * Register piano anlytics store in global Vuex store
   */
  await store.registerModule('pianoAnalytics', pianoAnalyticsStore, {
    preserveState: !!process.browser, // Keep track of this value, maybe someday this will trigger an error -Martijn.
  })
}
