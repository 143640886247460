const state = () => ({
  advertising: false,
  analytics: false,
  miscellaneous: false,
  necessary: false,
  recommendations: false,
  social: false,
})

const getters = {
  permissions: (state) => {
    // get all permissions, filter for approved permissions, return a string for every permission
    return Object.entries(state).filter(item => item[1]).map(item => item[0])
  },
}

const mutations = {
  advertising (state, value) {
    state.advertising = value
  },
  analytics (state, value) {
    state.analytics = value
  },
  miscellaneous (state, value) {
    state.miscellaneous = value
  },
  necessary (state, value) {
    state.necessary = value
  },
  recommendations (state, value) {
    state.recommendations = value
  },
  social (state, value) {
    state.social = value
  },
}

const actions = {
  setPermissions ({ commit }, permissions) {
    if (!permissions.length) {
      return
    }
    // set every permission seperate
    permissions.forEach(type => {
      commit(type, true)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
