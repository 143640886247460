
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_THEME_TABS, FOOTER_SHORTCUTS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      footerShortcuts: FOOTER_SHORTCUTS,
      isMounted: false,
      activeTab: 1,
      broadcastChannel: null,
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      intro: null,
      slug: null,
      subtitle: null,
      title: null,
      topic: null,
      articles: [],
      content: null,
      extraContent: null,
      pomsDescription: null,
      presenters: [],
      showExtraContent: false,
    }
  },
  async fetch () {
    const slug = this.$route?.params?.radio
    let publication = {}

    const [
      { data: { items: programs = [] } = {} },
    ] = await Promise.all([
      // Get radioprogram for this slug
      this.$api.programs.getOneBySlug(slug, {
        domain: this.$config.preprEnvironmentDomainGeneriek,
      }),
    ])

    publication = programs[0]

    this.broadcastChannel = publication.broadcastChannel
    this.hero = publication.hero
    this.heroVideo = publication.heroVideo
    this.heroVideoStill = publication.heroVideoStill
    this.id = publication.id
    this.intro = publication.intro
    this.slug = publication.slug
    this.subtitle = publication.subtitle
    this.title = publication.title
    this.pomsDescription = publication.pomsDescription
    this.content = publication.content
    this.extraContent = publication.extraContent
    this.topic = publication.topic
    this.presenters = publication.presenters

    if (slug) {
      // Check if we found a valid publication
      if (publication?.id) {
        const { data: { items: radioArticles = [], total = 0 } = {} } = await this.$api.programs.getAllNested(
          'articles',
          this.id,
          {
            offset: 0,
            limit: 4,
          },
        )

        if (radioArticles.length > 3) {
          this.showExtraContent = true
        }

        this.articles.push(...radioArticles)
        this.hasMoreArticlesAvailable = total > this.articles.length
        this.clips = []
      }
    }

    // Set breadcrumbs for the page
    this.$store.dispatch('breadcrumbs/set', {
      path: `/radio/${this.slug}`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          label: publication.title,
        },
      ],
    })
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    showAboutContent () {
      const hasArticles = this.articles && this.articles.length > 0
      const hasClips = this.clips && this.clips.length > 0

      return !hasArticles && !hasClips
    },
  },
  watch: {
    // Workaround layout caching bug
    '$route.params': {
        handler: function() {
          if (this.$fetch) {
            this.$fetch()
          }
        },
        deep: true,
        immediate: true,
      },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    hasContent(list) {
      return list && list.length
    },
  },
}
