/**
 * Breadcrumbs store
 * Modules mode
 * Nuxt.js lets you have a store directory with every file corresponding to a module.
 */
export const state = () => ({
  items: {},
  floating: {},
  eoRoot: {},
  width: {},
  mirrors: {},
})

export const getters = {
  items: state => (path) => {
    if (state.mirrors[path]) {
      return state.items[state.mirrors[path]]
    } else {
      return state.items[path]
    }
  },
  floating: state => (path) => {
    if (state.mirrors[path]) {
      return state.floating[state.mirrors[path]]
    } else {
      return state.floating[path]
    }
  },
  eoRoot: state => (path) => {
    if (state.mirrors[path]) {
      return state.eoRoot[state.mirrors[path]]
    } else {
      return state.eoRoot[path]
    }
  },
  width: state => (path) => {
    if (state.mirrors[path]) {
      return state.width[state.mirrors[path]]
    } else {
      return state.width[path]
    }
  },
}

export const mutations = {
  items: (state, { path, items }) => {
    state.items[path] = items
  },
  float: (state, { path, floating }) => {
    state.floating[path] = floating
  },
  root: (state, { path, eoRoot }) => {
    state.eoRoot[path] = eoRoot
  },
  width: (state, { path, width }) => {
    state.width[path] = width
  },
  mirror: (state, { path, mirror }) => {
    state.mirrors[path] = mirror
  },
}

export const actions = {
  set ({ commit }, {
    path,
    items = null,
    floating = false,
    eoRoot = false,
    width = 12,
  }) {
    if (path) {
      commit('items', { path, items })
      commit('float', { path, floating })
      commit('root', { path, eoRoot })
      commit('width', { path, width })
    }
  },
  mirror ({ commit }, { path, mirror }) {
    if (path && mirror) {
      commit('mirror', { path, mirror })
    }
  },
}
