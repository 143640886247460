export default function ({ $config, route: { path }, store}) {
  // ! Field updates for articles, rubrieken are done on the pages themselves because the project indicator must be fetched first
  if(path.startsWith('/artikel') || path.startsWith('/rubriek')) {
    return
  }
  // Automatically set project for programs, podcasts and nevenactiviteiten or set project at EO by default
  store.dispatch('pianoAnalytics/setProject',  getProject(path) || $config.trackingFieldHome )
}

const getProject = (path) => {
  const elements = path.toLowerCase().split('/')
  if(elements.length > 2 && elements[1] === 'programmas' || elements[1] === 'podcasts' || elements[1] === 'nevenactiviteit' ||  elements[1] === 'radio') {
    return elements[2]
  }
  return null
}
