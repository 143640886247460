const state = () => ({
  contextId: null,
  project: null,
  sandboxSiteId: null,
  siteId: null,
})

const getters = {
  contextId: state => state.contextId,
  project: state => state.project,
  sandboxSiteId: state => state.sandboxSiteId,
  siteId: state => state.siteId,
}

const mutations = {
  setContextId (state, value) {
    state.contextId = value
  },
  setProject (state, value) {
    state.project = value
  },
  setSandboxSiteId (state, value) {
    state.sandboxSiteId = value
  },
  setSiteId (state, value) {
    state.siteId = value
  },
}

const actions = {
  setContextId ({ commit }, value) {
    commit('setContextId', value)
  },
  setProject ({ commit }, value) {
    commit('setProject', value)
  },
  setSandboxSiteId ({ commit }, value) {
    commit('setSandboxSiteId', value)
  },
  setSiteId ({ commit }, value) {
    commit('setSiteId', value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
