// Action names must equal channel and mutation names (except the PREPR_CHANNEL_ and SET_ prefixes)
export const GENERAL_ACTIONS = Object.freeze({
  ACTION_CALL_TO_ACTION: 'call_to_action',
  ACTION_FEATURED: 'featured',
  ACTION_POPULAR: 'popular',
  ACTION_PODCASTS: 'podcasts',
  ACTION_RECOMMENDED: 'recommended',
  ACTION_EVENT: 'event',
  ACTION_MISSED: 'missed',
  ACTION_AHEAD: 'ahead',
})

export const TOPIC_ACTIONS = Object.freeze({
  ACTION_TOPIC_RECOMMENDED: 'topic_recommended',
  ACTION_TOPIC_CALL_TO_ACTION: 'topic_call_to_action',
  ACTION_TOPIC_DO: 'topic_do',
  ACTION_TOPIC_WATCH: 'topic_watch',
  ACTION_TOPIC_NEW: 'topic_new',
  ACTION_TOPIC_PODCASTS: 'topic_podcasts',
  ACTION_TOPIC_SUBTOPIC_1: 'topic_subtopic_1',
  ACTION_TOPIC_SUBTOPIC_2: 'topic_subtopic_2',
  ACTION_TOPIC_SUBTOPIC_3: 'topic_subtopic_3',
  ACTION_TOPIC_SUBTOPIC_4: 'topic_subtopic_4',
  ACTION_TOPIC_SUBTOPIC_5: 'topic_subtopic_5',
  ACTION_TOPIC_SUBPAGES: 'topic_subpages',
  ACTION_TOPIC_FEATURED: 'topic_featured',
})

export const PROGRAM_ACTIONS = Object.freeze({
  ACTION_PROGRAM_FEATURED: 'program_featured',
  ACTION_PROGRAM_POPULAR: 'program_popular',
  ACTION_PROGRAM_RECOMMENDED: 'program_recommended',
  ACTION_PROGRAM_MISSED: 'program_missed',
  ACTION_PROGRAM_RADIO: 'program_radio',
  ACTION_PROGRAM_AHEAD: 'program_ahead',
  ACTION_PROGRAM_NEW: 'program_new',
  ACTION_PROGRAM_AWARDED: 'program_awarded',
  ACTION_PROGRAM_TOPIC_1: 'program_topic_1',
  ACTION_PROGRAM_TOPIC_2: 'program_topic_2',
  ACTION_PROGRAM_TOPIC_3: 'program_topic_3',
  ACTION_PROGRAM_GENRE_1: 'program_genre_1',
  ACTION_PROGRAM_GENRE_2: 'program_genre_2',
  ACTION_PROGRAM_GENRE_3: 'program_genre_3',
  ACTION_PROGRAM_GENRE_4: 'program_genre_4',
  ACTION_PROGRAM_GENRE_5: 'program_genre_5',
})

export const PODCAST_ACTIONS = Object.freeze({
  ACTION_PODCAST_FEATURED: 'podcast_featured',
  ACTION_PODCAST_POPULAR: 'podcast_popular',
})
