// get the options out using lodash templates
const {
  ccmDomain,
  ccmWrapperVersion,
  logLevel,
  namespace,
  organisation,
  domain,
} = JSON.parse('{"ccmWrapperVersion":"v1.1","ccmDomain":"https://cookies.eo.nl","debug":false,"disable":false,"domain":"eo.nl","fakePermissions":null,"logLevel":"warn","namespace":"cookie-consent","organisation":"EO"}')
const log = require('loglevel').getLogger('cookie-consent-plugin-client')
log.setLevel(logLevel)

export default async ({ store, nuxtState }) => {
  let permissions = []

  // Check if session is server-side
  if (!nuxtState) {
    log.debug(`[${namespace}] Leaving client plugin because it's rendered server-side`)
    return
  }

  /**
   * Watch for injected script (below) to be loaded and fire CCM_Wrapper_ready
   */
  window.addEventListener('CCM_Wrapper_ready', () => {
    log.info(`[${namespace}] CCM_Wrapper_ready event fired`)
    if (window.ccmWrapper) {
      window.ccmWrapper.init()
    }
  }, false)

  /**
   * Inject Script in page
  */
  const element = document.createElement('script')
  element.type = 'text/javascript'
  element.crossOrigin = 'anonymous'
  element.src = ccmDomain + '/sites/' + organisation + '/' + domain + '/ccm-wrapper_' + ccmWrapperVersion + '.js'
  element.defer = element.async = true
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(element, firstScriptTag)

  /**
   * Check if ready
   * Get permissions and store them
   */
  const storePermissions = () => {
    /**
     * Check if NPO CCM is not loaded
     */
    if((typeof window.ccm === 'undefined') || (window.ccm.isDone() !== true)) {
      /**
       * Watch for NPO CCM eventthat tells if CCM is loaded
       */
      window.addEventListener('CCM_done', () => {
        log.debug(`[${namespace}] CCM_done event fired`)
        /**
         * Recursively execute this function again
         */
        storePermissions()
      }, false)
      return
    }

    /**
     * Get the permissions from NPO CCM
     */
    permissions = window.ccm.get_permissions()

    log.info(`${namespace} Storing permissions in vuex store`, { permissions })
    if (permissions.length) {
      /**
       * If permissions are given and available, store them
       */
      store.dispatch('cookieConsent/setPermissions', permissions)
    }
  }

  storePermissions()
}
