export const ICONS = Object.freeze({
  // Broadcasters
  NED1: 'npo-1',
  NED2: 'npo-2',
  NED3: 'npo-3',
  RAD1: 'npo-radio-1',
  RAD2: 'npo-radio-2',
  RAD3: 'npo-3fm',
  RAD4: 'npo-radio-4',
  RAD5: 'npo-radio-5',
  EO: 'npo-1',
  VPRO: 'npo-2',
  BNNVARA: 'npo-3',
  // Program types
  VIDEO: 'tv',
  AUDIO: 'headphones',
  // Topics
  TOPIC_ICON: "spirit",
})

export const PODCAST_LOGOS = Object.freeze({
  'APPLE PODCASTS': 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/7f9144da-d85f-4426-874e-5b48ec8eff24.jpg',
  'GOOGLE PODCASTS': 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/ae054b7b-5fd9-46dd-886d-7a39403c19a0.jpg',
  'THE PODCAST APP': 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/a910916e-ea82-4663-a4ff-5f494d5fddd7.png',
  SOUNDCLOUD: 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/64e57104-61b4-4858-9ea4-b0f50b73234a.jpg',
  SPOTIFY: 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/dcfb1080-4cd8-4e5f-ae7d-b13a7da0c3ad.jpg',
  RSS: 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/8e5fc5c3-8e7b-4e01-8908-f929d1ac67b4.png',
  NPO: 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/14239559-0e1b-4c81-8092-4fdf820189af.png',
  STITCHER: 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/775abad3-4bfa-4894-ba12-407e35049a7b.png',
})
