export const NotificationButton = () => import('../../node_modules/@eo/nuxt-notification/lib/components/NotificationButton/NotificationButton.vue' /* webpackChunkName: "components/notification-button" */).then(c => wrapFunctional(c.default || c))
export const NotificationItem = () => import('../../node_modules/@eo/nuxt-notification/lib/components/NotificationItem/NotificationItem.vue' /* webpackChunkName: "components/notification-item" */).then(c => wrapFunctional(c.default || c))
export const NotificationMenu = () => import('../../node_modules/@eo/nuxt-notification/lib/components/NotificationMenu/NotificationMenu.vue' /* webpackChunkName: "components/notification-menu" */).then(c => wrapFunctional(c.default || c))
export const EoFollowButton = () => import('../../node_modules/@eo/nuxt-follow/lib/components/EoFollowButton/EoFollowButton.vue' /* webpackChunkName: "components/eo-follow-button" */).then(c => wrapFunctional(c.default || c))
export const EoFollowGuideDialog = () => import('../../node_modules/@eo/nuxt-follow/lib/components/EoFollowGuideDialog/EoFollowGuideDialog.vue' /* webpackChunkName: "components/eo-follow-guide-dialog" */).then(c => wrapFunctional(c.default || c))
export const EoLikeButton = () => import('../../node_modules/@eo/nuxt-like/lib/components/EoLikeButton.vue' /* webpackChunkName: "components/eo-like-button" */).then(c => wrapFunctional(c.default || c))
export const LoginDialog = () => import('../../node_modules/@eo/nuxt-mijneo/lib/components/LoginDialog.vue' /* webpackChunkName: "components/login-dialog" */).then(c => wrapFunctional(c.default || c))
export const NameDialog = () => import('../../node_modules/@eo/nuxt-mijneo/lib/components/NameDialog.vue' /* webpackChunkName: "components/name-dialog" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../node_modules/@eo/nuxt-mijneo/lib/components/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const BannerPentecost = () => import('../../components/BannerPentecost.vue' /* webpackChunkName: "components/banner-pentecost" */).then(c => wrapFunctional(c.default || c))
export const MetterdaadInfographic = () => import('../../components/MetterdaadInfographic.vue' /* webpackChunkName: "components/metterdaad-infographic" */).then(c => wrapFunctional(c.default || c))
export const PreprPage = () => import('../../components/PreprPage.vue' /* webpackChunkName: "components/prepr-page" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BijbellabBijbelbabbelsLive = () => import('../../components/Bijbellab/BijbelbabbelsLive.vue' /* webpackChunkName: "components/bijbellab-bijbelbabbels-live" */).then(c => wrapFunctional(c.default || c))
export const BijbellabBijbelbabbelsLiveMessage = () => import('../../components/Bijbellab/BijbelbabbelsLiveMessage.vue' /* webpackChunkName: "components/bijbellab-bijbelbabbels-live-message" */).then(c => wrapFunctional(c.default || c))
export const BijbellabCard = () => import('../../components/Bijbellab/Card.vue' /* webpackChunkName: "components/bijbellab-card" */).then(c => wrapFunctional(c.default || c))
export const BijbellabFlessenpost = () => import('../../components/Bijbellab/Flessenpost.vue' /* webpackChunkName: "components/bijbellab-flessenpost" */).then(c => wrapFunctional(c.default || c))
export const BijbellabFlessenpostMessage = () => import('../../components/Bijbellab/FlessenpostMessage.vue' /* webpackChunkName: "components/bijbellab-flessenpost-message" */).then(c => wrapFunctional(c.default || c))
export const BijbellabLogo = () => import('../../components/Bijbellab/Logo.vue' /* webpackChunkName: "components/bijbellab-logo" */).then(c => wrapFunctional(c.default || c))
export const BijbellabManifestRow = () => import('../../components/Bijbellab/ManifestRow.vue' /* webpackChunkName: "components/bijbellab-manifest-row" */).then(c => wrapFunctional(c.default || c))
export const BijbellabManifestStack = () => import('../../components/Bijbellab/ManifestStack.vue' /* webpackChunkName: "components/bijbellab-manifest-stack" */).then(c => wrapFunctional(c.default || c))
export const BijbellabMoetJeHoren = () => import('../../components/Bijbellab/MoetJeHoren.vue' /* webpackChunkName: "components/bijbellab-moet-je-horen" */).then(c => wrapFunctional(c.default || c))
export const BijbellabMonogram = () => import('../../components/Bijbellab/Monogram.vue' /* webpackChunkName: "components/bijbellab-monogram" */).then(c => wrapFunctional(c.default || c))
export const BijbellabVraagHetDeBijbel = () => import('../../components/Bijbellab/VraagHetDeBijbel.vue' /* webpackChunkName: "components/bijbellab-vraag-het-de-bijbel" */).then(c => wrapFunctional(c.default || c))
export const BijbelseNamenFilter = () => import('../../components/BijbelseNamen/Filter.vue' /* webpackChunkName: "components/bijbelse-namen-filter" */).then(c => wrapFunctional(c.default || c))
export const ContainerdorpBlob = () => import('../../components/Containerdorp/Blob.vue' /* webpackChunkName: "components/containerdorp-blob" */).then(c => wrapFunctional(c.default || c))
export const HomeCardArticle = () => import('../../components/HomeCard/Article.vue' /* webpackChunkName: "components/home-card-article" */).then(c => wrapFunctional(c.default || c))
export const HomeCardCampaign = () => import('../../components/HomeCard/Campaign.vue' /* webpackChunkName: "components/home-card-campaign" */).then(c => wrapFunctional(c.default || c))
export const HomeCardCta = () => import('../../components/HomeCard/Cta.vue' /* webpackChunkName: "components/home-card-cta" */).then(c => wrapFunctional(c.default || c))
export const HomeCardDefault = () => import('../../components/HomeCard/Default.vue' /* webpackChunkName: "components/home-card-default" */).then(c => wrapFunctional(c.default || c))
export const HomeCardEpisode = () => import('../../components/HomeCard/Episode.vue' /* webpackChunkName: "components/home-card-episode" */).then(c => wrapFunctional(c.default || c))
export const HomeCardPodcast = () => import('../../components/HomeCard/Podcast.vue' /* webpackChunkName: "components/home-card-podcast" */).then(c => wrapFunctional(c.default || c))
export const HomeCardProgram = () => import('../../components/HomeCard/Program.vue' /* webpackChunkName: "components/home-card-program" */).then(c => wrapFunctional(c.default || c))
export const HomeCard = () => import('../../components/HomeCard/index.vue' /* webpackChunkName: "components/home-card" */).then(c => wrapFunctional(c.default || c))
export const HomeListItem = () => import('../../components/HomeListItem/HomeListItem.vue' /* webpackChunkName: "components/home-list-item" */).then(c => wrapFunctional(c.default || c))
export const KerstLetItSnow = () => import('../../components/Kerst/LetItSnow.vue' /* webpackChunkName: "components/kerst-let-it-snow" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/Layout/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/SectionTitle/SectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const HokjesExperimentCharacter = () => import('../../components/HokjesExperiment/Character/Character.vue' /* webpackChunkName: "components/hokjes-experiment-character" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
