import objectMapper from 'object-mapper'

export const topicCompact = (data) => {
  const config = {
    'id':'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.summary.body': 'summary',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': 'hero',
  }

  return objectMapper(data, config)
}

export const topic = (data) => {
  const config = {
    'id':'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.summary.body': 'summary',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': 'hero',
    'items.nl-NL.content.items[]': 'content[]',
    'items.nl-NL.playlist.items[]': 'playlist[]',
  }

  return objectMapper(data, config)
}
