import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _56c38a5a = () => interopDefault(import('../pages/bijbel-lab/index.vue' /* webpackChunkName: "pages/bijbel-lab/index" */))
const _581afaca = () => interopDefault(import('../pages/bijbelse-namen/index.vue' /* webpackChunkName: "pages/bijbelse-namen/index" */))
const _4f952c0a = () => interopDefault(import('../pages/gezichten-van-ter-apel/index.vue' /* webpackChunkName: "pages/gezichten-van-ter-apel/index" */))
const _dec3e7ce = () => interopDefault(import('../pages/link-in-bio.vue' /* webpackChunkName: "pages/link-in-bio" */))
const _2b65298e = () => interopDefault(import('../node_modules/@eo/nuxt-mijneo/lib/pages/Login.vue' /* webpackChunkName: "" */))
const _4edbc7fd = () => interopDefault(import('../pages/persoonlijke-verhalen.vue' /* webpackChunkName: "pages/persoonlijke-verhalen" */))
const _a05a03c8 = () => interopDefault(import('../pages/podcasts/index.vue' /* webpackChunkName: "pages/podcasts/index" */))
const _5342e688 = () => interopDefault(import('../node_modules/@eo/nuxt-mijneo/lib/pages/Profiel.vue' /* webpackChunkName: "" */))
const _05c02508 = () => interopDefault(import('../pages/programmas/index.vue' /* webpackChunkName: "pages/programmas/index" */))
const _4276c9a2 = () => interopDefault(import('../pages/redacteuren-en-gastauteurs/index.vue' /* webpackChunkName: "pages/redacteuren-en-gastauteurs/index" */))
const _32676224 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _e1e18332 = () => interopDefault(import('../pages/bijbel-lab/missie.vue' /* webpackChunkName: "pages/bijbel-lab/missie" */))
const _3ab73d4e = () => interopDefault(import('../pages/bijbelse-namen/zoeken/index.vue' /* webpackChunkName: "pages/bijbelse-namen/zoeken/index" */))
const _a227b370 = () => interopDefault(import('../pages/campagnes/kerst/index.vue' /* webpackChunkName: "pages/campagnes/kerst/index" */))
const _23a4444c = () => interopDefault(import('../pages/campagnes/pasen/index.vue' /* webpackChunkName: "pages/campagnes/pasen/index" */))
const _5e2bbed8 = () => interopDefault(import('../pages/campagnes/pinksteren/index.vue' /* webpackChunkName: "pages/campagnes/pinksteren/index" */))
const _7fc9b6ed = () => interopDefault(import('../pages/campagnes/zorgmedewerker-van-het-jaar/index.vue' /* webpackChunkName: "pages/campagnes/zorgmedewerker-van-het-jaar/index" */))
const _7d5e75fe = () => interopDefault(import('../pages/evenement/ben-jij-voorbereid.vue' /* webpackChunkName: "pages/evenement/ben-jij-voorbereid" */))
const _3c76d110 = () => interopDefault(import('../pages/nevenactiviteit/eva/index.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/index" */))
const _4792d6b8 = () => interopDefault(import('../pages/nevenactiviteit/visie/index.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/index" */))
const _727b2834 = () => interopDefault(import('../pages/programmas/eo-docs.vue' /* webpackChunkName: "pages/programmas/eo-docs" */))
const _0a0ffbe1 = () => interopDefault(import('../pages/programmas/eo-metterdaad/index.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/index" */))
const _601afe24 = () => interopDefault(import('../pages/programmas/joods-bij-de-eo.vue' /* webpackChunkName: "pages/programmas/joods-bij-de-eo" */))
const _2b71ceb8 = () => interopDefault(import('../pages/campagnes/kerst/actie.vue' /* webpackChunkName: "pages/campagnes/kerst/actie" */))
const _eca9ca4c = () => interopDefault(import('../pages/campagnes/kerst/net-binnen.vue' /* webpackChunkName: "pages/campagnes/kerst/net-binnen" */))
const _2988d036 = () => interopDefault(import('../pages/campagnes/pasen/actie.vue' /* webpackChunkName: "pages/campagnes/pasen/actie" */))
const _29f162f0 = () => interopDefault(import('../pages/campagnes/pasen/net-binnen.vue' /* webpackChunkName: "pages/campagnes/pasen/net-binnen" */))
const _0c4512f0 = () => interopDefault(import('../pages/campagnes/pinksteren/actie.vue' /* webpackChunkName: "pages/campagnes/pinksteren/actie" */))
const _3b09b00e = () => interopDefault(import('../pages/campagnes/pinksteren/net-binnen.vue' /* webpackChunkName: "pages/campagnes/pinksteren/net-binnen" */))
const _11c1dabc = () => interopDefault(import('../pages/campagnes/zorgmedewerker-van-het-jaar/nieuws.vue' /* webpackChunkName: "pages/campagnes/zorgmedewerker-van-het-jaar/nieuws" */))
const _1e8c19fb = () => interopDefault(import('../pages/nevenactiviteit/eva/artikelen.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/artikelen" */))
const _20474cf8 = () => interopDefault(import('../pages/nevenactiviteit/eva/evenementen.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenementen" */))
const _3fc443cd = () => interopDefault(import('../pages/nevenactiviteit/eva/trainingen.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/trainingen" */))
const _6823a127 = () => interopDefault(import('../pages/nevenactiviteit/visie/artikelen.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/artikelen" */))
const _6219b924 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenementen.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenementen" */))
const _45e5fea1 = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenementen.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenementen" */))
const _416dfdd6 = () => interopDefault(import('../pages/programmas/eo-metterdaad/over.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/over" */))
const _7ea924da = () => interopDefault(import('../pages/programmas/het-grote-hokjes-experiment/test.vue' /* webpackChunkName: "pages/programmas/het-grote-hokjes-experiment/test" */))
const _3a125abb = () => interopDefault(import('../pages/programmas/het-grote-hokjes-experiment/test-data.js' /* webpackChunkName: "pages/programmas/het-grote-hokjes-experiment/test-data" */))
const _7edf43f7 = () => interopDefault(import('../pages/programmas/welkom-in-containerdorp/test.vue' /* webpackChunkName: "pages/programmas/welkom-in-containerdorp/test" */))
const _73ec5f1f = () => interopDefault(import('../pages/programmas/welkom-in-containerdorp/test-data.js' /* webpackChunkName: "pages/programmas/welkom-in-containerdorp/test-data" */))
const _1c065333 = () => interopDefault(import('../pages/thema/zingen-en-bidden/week-van-gebed.vue' /* webpackChunkName: "pages/thema/zingen-en-bidden/week-van-gebed" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _58a98b24 = () => interopDefault(import('../pages/campagnes/zorgmedewerker-van-het-jaar/genomineerden/_slug.vue' /* webpackChunkName: "pages/campagnes/zorgmedewerker-van-het-jaar/genomineerden/_slug" */))
const _53249532 = () => interopDefault(import('../node_modules/@eo/nuxt-subscribe/lib/pages/SelectionPage.vue' /* webpackChunkName: "" */))
const _105bf4d6 = () => interopDefault(import('../node_modules/@eo/nuxt-single-sale/lib/pages/SingleSalePage.vue' /* webpackChunkName: "" */))
const _100f24b4 = () => interopDefault(import('../pages/nevenactiviteit/eva/campagne/_slug/index.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/campagne/_slug/index" */))
const _6040d38d = () => interopDefault(import('../pages/nevenactiviteit/eva/evenement/_slug/index.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenement/_slug/index" */))
const _ca821a0c = () => interopDefault(import('../pages/nevenactiviteit/visie/campagne/_slug/index.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/campagne/_slug/index" */))
const _164af8b9 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenement/_slug/index.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenement/_slug/index" */))
const _56c6db76 = () => interopDefault(import('../pages/programmas/eo-metterdaad/artikel/_slug.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/artikel/_slug" */))
const _3200c022 = () => interopDefault(import('../node_modules/@eo/nuxt-donate/lib/components/DonationPage.vue' /* webpackChunkName: "" */))
const _02f045f6 = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenement/_slug/index.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenement/_slug/index" */))
const _4fc7718c = () => interopDefault(import('../pages/programmas/eo-metterdaad/projecten/_slug.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/projecten/_slug" */))
const _1d90006b = () => interopDefault(import('../pages/nevenactiviteit/eva/campagne/_slug/line-up/index.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/campagne/_slug/line-up/index" */))
const _c12c09ba = () => interopDefault(import('../pages/nevenactiviteit/eva/campagne/_slug/nieuws.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/campagne/_slug/nieuws" */))
const _f138e15c = () => interopDefault(import('../pages/nevenactiviteit/eva/evenement/_slug/line-up/index.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenement/_slug/line-up/index" */))
const _251048c1 = () => interopDefault(import('../pages/nevenactiviteit/eva/evenement/_slug/livestream.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenement/_slug/livestream" */))
const _402e521c = () => interopDefault(import('../pages/nevenactiviteit/eva/evenement/_slug/nieuws.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenement/_slug/nieuws" */))
const _63141c82 = () => interopDefault(import('../pages/nevenactiviteit/visie/campagne/_slug/line-up/index.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/campagne/_slug/line-up/index" */))
const _5574204f = () => interopDefault(import('../pages/nevenactiviteit/visie/campagne/_slug/nieuws.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/campagne/_slug/nieuws" */))
const _19886f04 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenement/_slug/line-up/index.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenement/_slug/line-up/index" */))
const _13aa7f15 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenement/_slug/livestream.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenement/_slug/livestream" */))
const _4b68d270 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenement/_slug/nieuws.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenement/_slug/nieuws" */))
const _549288bb = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenement/_slug/line-up/index.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenement/_slug/line-up/index" */))
const _1364eff8 = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenement/_slug/livestream.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenement/_slug/livestream" */))
const _1925a65a = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenement/_slug/nieuws.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenement/_slug/nieuws" */))
const _51c452cd = () => interopDefault(import('../pages/nevenactiviteit/eva/campagne/_slug/line-up/_person.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/campagne/_slug/line-up/_person" */))
const _d6207818 = () => interopDefault(import('../pages/nevenactiviteit/eva/evenement/_slug/line-up/_person.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenement/_slug/line-up/_person" */))
const _60f33f21 = () => interopDefault(import('../pages/nevenactiviteit/visie/campagne/_slug/line-up/_person.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/campagne/_slug/line-up/_person" */))
const _6b9e6220 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenement/_slug/line-up/_person.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenement/_slug/line-up/_person" */))
const _5b73f1c6 = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenement/_slug/line-up/_person.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenement/_slug/line-up/_person" */))
const _d3693a20 = () => interopDefault(import('../node_modules/@eo/nuxt-subscribe/lib/pages/SubscribePage.vue' /* webpackChunkName: "" */))
const _60514552 = () => interopDefault(import('../pages/nevenactiviteit/eva/campagne/_slug/_field.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/campagne/_slug/_field" */))
const _709bb450 = () => interopDefault(import('../pages/nevenactiviteit/eva/evenement/_slug/_field.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/evenement/_slug/_field" */))
const _f43cfafa = () => interopDefault(import('../pages/nevenactiviteit/visie/campagne/_slug/_field.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/campagne/_slug/_field" */))
const _7bd634a4 = () => interopDefault(import('../pages/nevenactiviteit/visie/evenement/_slug/_field.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/evenement/_slug/_field" */))
const _23da8f07 = () => interopDefault(import('../pages/programmas/eo-metterdaad/evenement/_slug/_field.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/evenement/_slug/_field" */))
const _010735ac = () => interopDefault(import('../pages/bijbelse-namen/zoeken/_story.vue' /* webpackChunkName: "pages/bijbelse-namen/zoeken/_story" */))
const _114a8e16 = () => interopDefault(import('../pages/campagnes/kerst/_field.vue' /* webpackChunkName: "pages/campagnes/kerst/_field" */))
const _bf6018ba = () => interopDefault(import('../pages/campagnes/pasen/_field.vue' /* webpackChunkName: "pages/campagnes/pasen/_field" */))
const _151c0829 = () => interopDefault(import('../pages/campagnes/pinksteren/_field.vue' /* webpackChunkName: "pages/campagnes/pinksteren/_field" */))
const _422f3cf0 = () => interopDefault(import('../pages/campagnes/zorgmedewerker-van-het-jaar/_field.vue' /* webpackChunkName: "pages/campagnes/zorgmedewerker-van-het-jaar/_field" */))
const _4a85dc14 = () => interopDefault(import('../pages/nevenactiviteit/eva/_page.vue' /* webpackChunkName: "pages/nevenactiviteit/eva/_page" */))
const _44f7d940 = () => interopDefault(import('../pages/nevenactiviteit/visie/_page.vue' /* webpackChunkName: "pages/nevenactiviteit/visie/_page" */))
const _72d1407d = () => interopDefault(import('../pages/programmas/eo-metterdaad/_page.vue' /* webpackChunkName: "pages/programmas/eo-metterdaad/_page" */))
const _01bf4077 = () => interopDefault(import('../pages/alles-over/_slug.vue' /* webpackChunkName: "pages/alles-over/_slug" */))
const _3ec78eed = () => interopDefault(import('../pages/artikel/_slug.vue' /* webpackChunkName: "pages/artikel/_slug" */))
const _95b7129c = () => interopDefault(import('../pages/bijbel-lab/_page/index.vue' /* webpackChunkName: "pages/bijbel-lab/_page/index" */))
const _772e8133 = () => interopDefault(import('../pages/bijbelse-namen/_name.vue' /* webpackChunkName: "pages/bijbelse-namen/_name" */))
const _890f7c4e = () => interopDefault(import('../pages/campagnes/_slug/index.vue' /* webpackChunkName: "pages/campagnes/_slug/index" */))
const _9f44db26 = () => interopDefault(import('../pages/evenement/_slug/index.vue' /* webpackChunkName: "pages/evenement/_slug/index" */))
const _53026e9a = () => interopDefault(import('../pages/gezichten-van-ter-apel/_slug.vue' /* webpackChunkName: "pages/gezichten-van-ter-apel/_slug" */))
const _76fb8b2e = () => interopDefault(import('../pages/podcasts/_podcast/index.vue' /* webpackChunkName: "pages/podcasts/_podcast/index" */))
const _b3db119e = () => interopDefault(import('../pages/podcasts/_podcast/index/index.vue' /* webpackChunkName: "pages/podcasts/_podcast/index/index" */))
const _2400624e = () => interopDefault(import('../pages/podcasts/_podcast/index/alle-afleveringen.vue' /* webpackChunkName: "pages/podcasts/_podcast/index/alle-afleveringen" */))
const _1ff2fb94 = () => interopDefault(import('../pages/podcasts/_podcast/index/extras.vue' /* webpackChunkName: "pages/podcasts/_podcast/index/extras" */))
const _2661dbc5 = () => interopDefault(import('../pages/podcasts/_podcast/index/over.vue' /* webpackChunkName: "pages/podcasts/_podcast/index/over" */))
const _32d80c8a = () => interopDefault(import('../pages/presentatoren/_slug.vue' /* webpackChunkName: "pages/presentatoren/_slug" */))
const _4e86ad7c = () => interopDefault(import('../pages/programmas/_program/index.vue' /* webpackChunkName: "pages/programmas/_program/index" */))
const _861e1506 = () => interopDefault(import('../pages/radio/_radio/index.vue' /* webpackChunkName: "pages/radio/_radio/index" */))
const _40c0285a = () => interopDefault(import('../pages/redacteuren-en-gastauteurs/_slug.vue' /* webpackChunkName: "pages/redacteuren-en-gastauteurs/_slug" */))
const _6c38c4dd = () => interopDefault(import('../pages/rubriek/_slug.vue' /* webpackChunkName: "pages/rubriek/_slug" */))
const _7c802db2 = () => interopDefault(import('../pages/thema/_topic/index.vue' /* webpackChunkName: "pages/thema/_topic/index" */))
const _385b0115 = () => interopDefault(import('../pages/bijbel-lab/_page/standalone.vue' /* webpackChunkName: "pages/bijbel-lab/_page/standalone" */))
const _279df19e = () => interopDefault(import('../pages/campagnes/_slug/line-up/index.vue' /* webpackChunkName: "pages/campagnes/_slug/line-up/index" */))
const _4be4ad50 = () => interopDefault(import('../pages/campagnes/_slug/nieuws.vue' /* webpackChunkName: "pages/campagnes/_slug/nieuws" */))
const _fc79239c = () => interopDefault(import('../pages/evenement/_slug/line-up/index.vue' /* webpackChunkName: "pages/evenement/_slug/line-up/index" */))
const _3d77d5e1 = () => interopDefault(import('../pages/evenement/_slug/livestream.vue' /* webpackChunkName: "pages/evenement/_slug/livestream" */))
const _18ad2188 = () => interopDefault(import('../pages/evenement/_slug/nieuws.vue' /* webpackChunkName: "pages/evenement/_slug/nieuws" */))
const _ed38b334 = () => interopDefault(import('../pages/programmas/_program/afleveringen.vue' /* webpackChunkName: "pages/programmas/_program/afleveringen" */))
const _428f0ba3 = () => interopDefault(import('../pages/programmas/_program/extras.vue' /* webpackChunkName: "pages/programmas/_program/extras" */))
const _5990a394 = () => interopDefault(import('../pages/programmas/_program/over.vue' /* webpackChunkName: "pages/programmas/_program/over" */))
const _36377270 = () => interopDefault(import('../pages/radio/_radio/extras.vue' /* webpackChunkName: "pages/radio/_radio/extras" */))
const _0457380e = () => interopDefault(import('../pages/radio/_radio/over.vue' /* webpackChunkName: "pages/radio/_radio/over" */))
const _101ac340 = () => interopDefault(import('../pages/campagnes/_slug/line-up/_person.vue' /* webpackChunkName: "pages/campagnes/_slug/line-up/_person" */))
const _76d36ad4 = () => interopDefault(import('../pages/evenement/_slug/line-up/_person.vue' /* webpackChunkName: "pages/evenement/_slug/line-up/_person" */))
const _7c520f84 = () => interopDefault(import('../pages/campagnes/_slug/_field.vue' /* webpackChunkName: "pages/campagnes/_slug/_field" */))
const _2416d170 = () => interopDefault(import('../pages/evenement/_slug/_field.vue' /* webpackChunkName: "pages/evenement/_slug/_field" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bijbel-lab",
    component: _56c38a5a,
    name: "bijbel-lab"
  }, {
    path: "/bijbelse-namen",
    component: _581afaca,
    name: "bijbelse-namen"
  }, {
    path: "/gezichten-van-ter-apel",
    component: _4f952c0a,
    name: "gezichten-van-ter-apel"
  }, {
    path: "/link-in-bio",
    component: _dec3e7ce,
    name: "link-in-bio"
  }, {
    path: "/login",
    component: _2b65298e,
    alias: "/inloggen",
    name: "login"
  }, {
    path: "/persoonlijke-verhalen",
    component: _4edbc7fd,
    name: "persoonlijke-verhalen"
  }, {
    path: "/podcasts",
    component: _a05a03c8,
    name: "podcasts"
  }, {
    path: "/profiel",
    component: _5342e688,
    alias: ["/mijn","/mijneo","/mijn-eo"],
    name: "profiel"
  }, {
    path: "/programmas",
    component: _05c02508,
    name: "programmas"
  }, {
    path: "/redacteuren-en-gastauteurs",
    component: _4276c9a2,
    name: "redacteuren-en-gastauteurs"
  }, {
    path: "/zoeken",
    component: _32676224,
    name: "zoeken"
  }, {
    path: "/bijbel-lab/missie",
    component: _e1e18332,
    name: "bijbel-lab-missie"
  }, {
    path: "/bijbelse-namen/zoeken",
    component: _3ab73d4e,
    name: "bijbelse-namen-zoeken"
  }, {
    path: "/campagnes/kerst",
    component: _a227b370,
    name: "campagnes-kerst"
  }, {
    path: "/campagnes/pasen",
    component: _23a4444c,
    name: "campagnes-pasen"
  }, {
    path: "/campagnes/pinksteren",
    component: _5e2bbed8,
    name: "campagnes-pinksteren"
  }, {
    path: "/campagnes/zorgmedewerker-van-het-jaar",
    component: _7fc9b6ed,
    name: "campagnes-zorgmedewerker-van-het-jaar"
  }, {
    path: "/evenement/ben-jij-voorbereid",
    component: _7d5e75fe,
    name: "evenement-ben-jij-voorbereid"
  }, {
    path: "/nevenactiviteit/eva",
    component: _3c76d110,
    name: "nevenactiviteit-eva"
  }, {
    path: "/nevenactiviteit/visie",
    component: _4792d6b8,
    name: "nevenactiviteit-visie"
  }, {
    path: "/programmas/eo-docs",
    component: _727b2834,
    name: "programmas-eo-docs"
  }, {
    path: "/programmas/eo-metterdaad",
    component: _0a0ffbe1,
    name: "programmas-eo-metterdaad"
  }, {
    path: "/programmas/joods-bij-de-eo",
    component: _601afe24,
    name: "programmas-joods-bij-de-eo"
  }, {
    path: "/campagnes/kerst/actie",
    component: _2b71ceb8,
    name: "campagnes-kerst-actie"
  }, {
    path: "/campagnes/kerst/net-binnen",
    component: _eca9ca4c,
    name: "campagnes-kerst-net-binnen"
  }, {
    path: "/campagnes/pasen/actie",
    component: _2988d036,
    name: "campagnes-pasen-actie"
  }, {
    path: "/campagnes/pasen/net-binnen",
    component: _29f162f0,
    name: "campagnes-pasen-net-binnen"
  }, {
    path: "/campagnes/pinksteren/actie",
    component: _0c4512f0,
    name: "campagnes-pinksteren-actie"
  }, {
    path: "/campagnes/pinksteren/net-binnen",
    component: _3b09b00e,
    name: "campagnes-pinksteren-net-binnen"
  }, {
    path: "/campagnes/zorgmedewerker-van-het-jaar/nieuws",
    component: _11c1dabc,
    name: "campagnes-zorgmedewerker-van-het-jaar-nieuws"
  }, {
    path: "/nevenactiviteit/eva/artikelen",
    component: _1e8c19fb,
    name: "nevenactiviteit-eva-artikelen"
  }, {
    path: "/nevenactiviteit/eva/evenementen",
    component: _20474cf8,
    name: "nevenactiviteit-eva-evenementen"
  }, {
    path: "/nevenactiviteit/eva/trainingen",
    component: _3fc443cd,
    name: "nevenactiviteit-eva-trainingen"
  }, {
    path: "/nevenactiviteit/visie/artikelen",
    component: _6823a127,
    name: "nevenactiviteit-visie-artikelen"
  }, {
    path: "/nevenactiviteit/visie/evenementen",
    component: _6219b924,
    name: "nevenactiviteit-visie-evenementen"
  }, {
    path: "/programmas/eo-metterdaad/evenementen",
    component: _45e5fea1,
    name: "programmas-eo-metterdaad-evenementen"
  }, {
    path: "/programmas/eo-metterdaad/over",
    component: _416dfdd6,
    name: "programmas-eo-metterdaad-over"
  }, {
    path: "/programmas/het-grote-hokjes-experiment/test",
    component: _7ea924da,
    name: "programmas-het-grote-hokjes-experiment-test"
  }, {
    path: "/programmas/het-grote-hokjes-experiment/test-data",
    component: _3a125abb,
    name: "programmas-het-grote-hokjes-experiment-test-data"
  }, {
    path: "/programmas/welkom-in-containerdorp/test",
    component: _7edf43f7,
    name: "programmas-welkom-in-containerdorp-test"
  }, {
    path: "/programmas/welkom-in-containerdorp/test-data",
    component: _73ec5f1f,
    name: "programmas-welkom-in-containerdorp-test-data"
  }, {
    path: "/thema/zingen-en-bidden/week-van-gebed",
    component: _1c065333,
    name: "thema-zingen-en-bidden-week-van-gebed"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/campagnes/zorgmedewerker-van-het-jaar/genomineerden/:slug?",
    component: _58a98b24,
    name: "campagnes-zorgmedewerker-van-het-jaar-genomineerden-slug"
  }, {
    path: "/nevenactiviteit/eva/abonneren/:selection",
    component: _53249532,
    props: {"colors":{"primary":"red-600","secondary":"red-300","button":"red-600"},"organisation":"EO","brand":"eva"},
    name: "/nevenactiviteit/eva/abonneren-subscribe"
  }, {
    path: "/nevenactiviteit/eva/bestel/:singleSale",
    component: _105bf4d6,
    props: {"colors":{"primary":"red-600","secondary":"red-300","background":"red-50","buttonDisabled":"red-50"},"organisation":"EO","brand":"eva"},
    name: "/nevenactiviteit/eva/bestel-singlesale"
  }, {
    path: "/nevenactiviteit/eva/campagne/:slug",
    component: _100f24b4,
    name: "nevenactiviteit-eva-campagne-slug"
  }, {
    path: "/nevenactiviteit/eva/evenement/:slug",
    component: _6040d38d,
    name: "nevenactiviteit-eva-evenement-slug"
  }, {
    path: "/nevenactiviteit/visie/abonneren/:selection",
    component: _53249532,
    props: {"colors":{"primary":"red-400","secondary":"red-50","button":"red-400"},"organisation":"EO","brand":"visie"},
    name: "/nevenactiviteit/visie/abonneren-subscribe"
  }, {
    path: "/nevenactiviteit/visie/bestel/:singleSale",
    component: _105bf4d6,
    props: {"colors":{"primary":"red-400","secondary":"red-400","background":"red-50","buttonDisabled":"red-200"},"organisation":"EO","brand":"visie"},
    name: "/nevenactiviteit/visie/bestel-singlesale"
  }, {
    path: "/nevenactiviteit/visie/campagne/:slug",
    component: _ca821a0c,
    name: "nevenactiviteit-visie-campagne-slug"
  }, {
    path: "/nevenactiviteit/visie/evenement/:slug",
    component: _164af8b9,
    name: "nevenactiviteit-visie-evenement-slug"
  }, {
    path: "/programmas/eo-metterdaad/artikel/:slug",
    component: _56c6db76,
    name: "programmas-eo-metterdaad-artikel-slug"
  }, {
    path: "/programmas/eo-metterdaad/doneren/:donation",
    component: _3200c022,
    props: {"organisation":"MTD","homeUrl":"/programmas/eo-metterdaad","brand":"eo-metterdaad","color":"orange-400"},
    name: "/programmas/eo-metterdaad/doneren-donation"
  }, {
    path: "/programmas/eo-metterdaad/evenement/:slug",
    component: _02f045f6,
    name: "programmas-eo-metterdaad-evenement-slug"
  }, {
    path: "/programmas/eo-metterdaad/projecten/:slug",
    component: _4fc7718c,
    name: "programmas-eo-metterdaad-projecten-slug"
  }, {
    path: "/nevenactiviteit/eva/campagne/:slug?/line-up",
    component: _1d90006b,
    name: "nevenactiviteit-eva-campagne-slug-line-up"
  }, {
    path: "/nevenactiviteit/eva/campagne/:slug?/nieuws",
    component: _c12c09ba,
    name: "nevenactiviteit-eva-campagne-slug-nieuws"
  }, {
    path: "/nevenactiviteit/eva/evenement/:slug?/line-up",
    component: _f138e15c,
    name: "nevenactiviteit-eva-evenement-slug-line-up"
  }, {
    path: "/nevenactiviteit/eva/evenement/:slug?/livestream",
    component: _251048c1,
    name: "nevenactiviteit-eva-evenement-slug-livestream"
  }, {
    path: "/nevenactiviteit/eva/evenement/:slug?/nieuws",
    component: _402e521c,
    name: "nevenactiviteit-eva-evenement-slug-nieuws"
  }, {
    path: "/nevenactiviteit/visie/campagne/:slug?/line-up",
    component: _63141c82,
    name: "nevenactiviteit-visie-campagne-slug-line-up"
  }, {
    path: "/nevenactiviteit/visie/campagne/:slug?/nieuws",
    component: _5574204f,
    name: "nevenactiviteit-visie-campagne-slug-nieuws"
  }, {
    path: "/nevenactiviteit/visie/evenement/:slug?/line-up",
    component: _19886f04,
    name: "nevenactiviteit-visie-evenement-slug-line-up"
  }, {
    path: "/nevenactiviteit/visie/evenement/:slug?/livestream",
    component: _13aa7f15,
    name: "nevenactiviteit-visie-evenement-slug-livestream"
  }, {
    path: "/nevenactiviteit/visie/evenement/:slug?/nieuws",
    component: _4b68d270,
    name: "nevenactiviteit-visie-evenement-slug-nieuws"
  }, {
    path: "/programmas/eo-metterdaad/evenement/:slug/line-up",
    component: _549288bb,
    name: "programmas-eo-metterdaad-evenement-slug-line-up"
  }, {
    path: "/programmas/eo-metterdaad/evenement/:slug/livestream",
    component: _1364eff8,
    name: "programmas-eo-metterdaad-evenement-slug-livestream"
  }, {
    path: "/programmas/eo-metterdaad/evenement/:slug/nieuws",
    component: _1925a65a,
    name: "programmas-eo-metterdaad-evenement-slug-nieuws"
  }, {
    path: "/nevenactiviteit/eva/campagne/:slug?/line-up/:person?",
    component: _51c452cd,
    name: "nevenactiviteit-eva-campagne-slug-line-up-person"
  }, {
    path: "/nevenactiviteit/eva/evenement/:slug?/line-up/:person?",
    component: _d6207818,
    name: "nevenactiviteit-eva-evenement-slug-line-up-person"
  }, {
    path: "/nevenactiviteit/visie/campagne/:slug?/line-up/:person?",
    component: _60f33f21,
    name: "nevenactiviteit-visie-campagne-slug-line-up-person"
  }, {
    path: "/nevenactiviteit/visie/evenement/:slug?/line-up/:person?",
    component: _6b9e6220,
    name: "nevenactiviteit-visie-evenement-slug-line-up-person"
  }, {
    path: "/programmas/eo-metterdaad/evenement/:slug/line-up/:person",
    component: _5b73f1c6,
    name: "programmas-eo-metterdaad-evenement-slug-line-up-person"
  }, {
    path: "/nevenactiviteit/eva/abonneren/:selection/:subscription",
    component: _d3693a20,
    props: {"colors":{"primary":"red-600","secondary":"red-300","background":"red-50","buttonDisabled":"red-100"},"organisation":"EO","brand":"eva"},
    name: "/nevenactiviteit/eva/abonneren-subscribe"
  }, {
    path: "/nevenactiviteit/eva/campagne/:slug?/:field",
    component: _60514552,
    name: "nevenactiviteit-eva-campagne-slug-field"
  }, {
    path: "/nevenactiviteit/eva/evenement/:slug?/:field",
    component: _709bb450,
    name: "nevenactiviteit-eva-evenement-slug-field"
  }, {
    path: "/nevenactiviteit/visie/abonneren/:selection/:subscription",
    component: _d3693a20,
    props: {"colors":{"primary":"red-400","secondary":"red-400","background":"red-50","buttonDisabled":"red-200"},"organisation":"EO","brand":"visie"},
    name: "/nevenactiviteit/visie/abonneren-subscribe"
  }, {
    path: "/nevenactiviteit/visie/campagne/:slug?/:field",
    component: _f43cfafa,
    name: "nevenactiviteit-visie-campagne-slug-field"
  }, {
    path: "/nevenactiviteit/visie/evenement/:slug?/:field",
    component: _7bd634a4,
    name: "nevenactiviteit-visie-evenement-slug-field"
  }, {
    path: "/programmas/eo-metterdaad/evenement/:slug/:field?",
    component: _23da8f07,
    name: "programmas-eo-metterdaad-evenement-slug-field"
  }, {
    path: "/bijbelse-namen/zoeken/:story",
    component: _010735ac,
    name: "bijbelse-namen-zoeken-story"
  }, {
    path: "/campagnes/kerst/:field",
    component: _114a8e16,
    name: "campagnes-kerst-field"
  }, {
    path: "/campagnes/pasen/:field",
    component: _bf6018ba,
    name: "campagnes-pasen-field"
  }, {
    path: "/campagnes/pinksteren/:field",
    component: _151c0829,
    name: "campagnes-pinksteren-field"
  }, {
    path: "/campagnes/zorgmedewerker-van-het-jaar/:field?",
    component: _422f3cf0,
    name: "campagnes-zorgmedewerker-van-het-jaar-field"
  }, {
    path: "/nevenactiviteit/eva/:page",
    component: _4a85dc14,
    name: "nevenactiviteit-eva-page"
  }, {
    path: "/nevenactiviteit/visie/:page",
    component: _44f7d940,
    name: "nevenactiviteit-visie-page"
  }, {
    path: "/programmas/eo-metterdaad/:page?",
    component: _72d1407d,
    name: "programmas-eo-metterdaad-page"
  }, {
    path: "/alles-over/:slug?",
    component: _01bf4077,
    name: "alles-over-slug"
  }, {
    path: "/artikel/:slug?",
    component: _3ec78eed,
    name: "artikel-slug"
  }, {
    path: "/bijbel-lab/:page",
    component: _95b7129c,
    name: "bijbel-lab-page"
  }, {
    path: "/bijbelse-namen/:name?",
    component: _772e8133,
    name: "bijbelse-namen-name"
  }, {
    path: "/campagnes/:slug",
    component: _890f7c4e,
    name: "campagnes-slug"
  }, {
    path: "/evenement/:slug",
    component: _9f44db26,
    name: "evenement-slug"
  }, {
    path: "/gezichten-van-ter-apel/:slug?",
    component: _53026e9a,
    name: "gezichten-van-ter-apel-slug"
  }, {
    path: "/podcasts/:podcast",
    component: _76fb8b2e,
    children: [{
      path: "",
      component: _b3db119e,
      name: "podcasts-podcast-index"
    }, {
      path: "alle-afleveringen",
      component: _2400624e,
      name: "podcasts-podcast-index-alle-afleveringen"
    }, {
      path: "extras",
      component: _1ff2fb94,
      name: "podcasts-podcast-index-extras"
    }, {
      path: "over",
      component: _2661dbc5,
      name: "podcasts-podcast-index-over"
    }]
  }, {
    path: "/presentatoren/:slug?",
    component: _32d80c8a,
    name: "presentatoren-slug"
  }, {
    path: "/programmas/:program",
    component: _4e86ad7c,
    name: "programmas-program"
  }, {
    path: "/radio/:radio",
    component: _861e1506,
    name: "radio-radio"
  }, {
    path: "/redacteuren-en-gastauteurs/:slug?",
    component: _40c0285a,
    name: "redacteuren-en-gastauteurs-slug"
  }, {
    path: "/rubriek/:slug?",
    component: _6c38c4dd,
    name: "rubriek-slug"
  }, {
    path: "/thema/:topic",
    component: _7c802db2,
    name: "thema-topic"
  }, {
    path: "/bijbel-lab/:page?/standalone",
    component: _385b0115,
    name: "bijbel-lab-page-standalone"
  }, {
    path: "/campagnes/:slug?/line-up",
    component: _279df19e,
    name: "campagnes-slug-line-up"
  }, {
    path: "/campagnes/:slug?/nieuws",
    component: _4be4ad50,
    name: "campagnes-slug-nieuws"
  }, {
    path: "/evenement/:slug?/line-up",
    component: _fc79239c,
    name: "evenement-slug-line-up"
  }, {
    path: "/evenement/:slug?/livestream",
    component: _3d77d5e1,
    name: "evenement-slug-livestream"
  }, {
    path: "/evenement/:slug?/nieuws",
    component: _18ad2188,
    name: "evenement-slug-nieuws"
  }, {
    path: "/programmas/:program/afleveringen",
    component: _ed38b334,
    name: "programmas-program-afleveringen"
  }, {
    path: "/programmas/:program/extras",
    component: _428f0ba3,
    name: "programmas-program-extras"
  }, {
    path: "/programmas/:program/over",
    component: _5990a394,
    name: "programmas-program-over"
  }, {
    path: "/radio/:radio?/extras",
    component: _36377270,
    name: "radio-radio-extras"
  }, {
    path: "/radio/:radio?/over",
    component: _0457380e,
    name: "radio-radio-over"
  }, {
    path: "/campagnes/:slug?/line-up/:person?",
    component: _101ac340,
    name: "campagnes-slug-line-up-person"
  }, {
    path: "/evenement/:slug?/line-up/:person?",
    component: _76d36ad4,
    name: "evenement-slug-line-up-person"
  }, {
    path: "/campagnes/:slug?/:field",
    component: _7c520f84,
    name: "campagnes-slug-field"
  }, {
    path: "/evenement/:slug?/:field",
    component: _2416d170,
    name: "evenement-slug-field"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
