/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty'
import { CHANNELS, GENERAL_ACTIONS, API_CONSTANTS } from '../constants'
import { getMutationNamesFromActions, hasMoreItemsAvailable, getRequestParams, filterStateByModel, getMutation } from '~/utils'

const channelMutations = getMutationNamesFromActions(GENERAL_ACTIONS)

const state = () => ({
  call_to_action: {},
  featured: {},
  popular: {},
  podcasts: {},
  recommended: {},
  missed: {},
  ahead: {},
  event: {},
})

const getters = {
  call_to_action: state => model => filterStateByModel(state.call_to_action.items, model),
  featured: state => model => filterStateByModel(state.featured.items, model),
  popular: state => model => filterStateByModel(state.popular.items, model),
  podcasts: state => model => filterStateByModel(state.podcasts.items, model),
  recommended: state => model => filterStateByModel(state.recommended.items, model),
  missed: state => model => filterStateByModel(state.missed.items, model),
  ahead: state => model => filterStateByModel(state.ahead.items, model),
  event: state => model => filterStateByModel(state.event.items, model),
}

const mutations = {
  SET_CALL_TO_ACTION: (state, payload) => {
    state.call_to_action = getMutation(state.call_to_action, payload)
  },
  SET_FEATURED: (state, payload) => {
    state.featured = getMutation(state.featured, payload)
  },
  SET_POPULAR: (state, payload) => {
    state.popular = getMutation(state.popular, payload)
  },
  SET_PODCASTS: (state, payload) => {
    state.podcasts = getMutation(state.podcasts, payload)
  },
  SET_RECOMMENDED: (state, payload) => {
    state.recommended = getMutation(state.recommended, payload)
  },
  SET_MISSED: (state, payload) => {
    state.missed = getMutation(state.missed, payload)
  },
  SET_AHEAD: (state, payload) => {
    state.ahead = getMutation(state.ahead, payload)
  },
  SET_EVENT: (state, payload) => {
    state.event = getMutation(state.event, payload)
  },
}

const actions = {
  async proxyAction ({ dispatch, commit, state }, params) {
    if (!params?.action) {
      return
    }
    dispatch('loading/setLoading', true, { root: true })
    try {
      const storeItems = state[params.action]
      const requestParams = getRequestParams(
        storeItems?.items,
        params,
        CHANNELS,
        API_CONSTANTS.LIMIT_PROGRAMS,
        API_CONSTANTS.PREPR_DOMAIN_HOME)
      const hasNotInStore = (requestParams.offset + requestParams.limit) > storeItems?.items?.length

      if ((hasMoreItemsAvailable(storeItems) && hasNotInStore) || isEmpty(storeItems)) {
        const response = await this.$api.teasers.getAll(requestParams)
        commit(channelMutations[params.action], response.data)
      }

      dispatch('loading/setLoading', false, { root: true })
    } catch (error) {
      this.$log.warn(error)
      dispatch('loading/setLoading', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
