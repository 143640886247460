import objectMapper from 'object-mapper'
import { communityCompact } from './community'

export const personCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.nickname.body': 'nickname',
    'items.nl-NL.role.items[]': 'roles[]',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.phone.body': 'phone',
    'items.nl-NL.email.body': 'email',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.community.items[0]': {
      key: 'community',
      transform: p => p && communityCompact(p),
    },
    'containers[].body': 'stories[].name',
    'containers[].slug': 'stories[].slug',
  }

  return objectMapper(data, config)
}

export const person = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.nickname.body': 'nickname',
    'items.nl-NL.role.items[]': 'roles[]',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.sex.body': 'sex',
    'items.nl-NL.phone.body': 'phone',
    'items.nl-NL.email.body': 'email',
    'items.nl-NL.facebook.body': 'facebook',
    'items.nl-NL.instagram.body': 'instagram',
    'items.nl-NL.linkedin.body': 'linkedin',
    'items.nl-NL.snapchat.body': 'snapchat',
    'items.nl-NL.spotify.body': 'spotify',
    'items.nl-NL.tiktok.body': 'tiktok',
    'items.nl-NL.twitter.body': 'twitter',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.biography.items[]': 'biography[]',
    'items.nl-NL.downloads.items': {
      key: 'downloads',
      transform: items => (items && {
        images: items.filter(d => d.label === 'Photo'),
        others: items.filter(d => d.label !== 'Photo'),
      }),
    },
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.contact.items[]': {
      key: 'contacts',
      transform: (publications) => {
        if (!publications || !publications.length) {
          return null
        }
        return publications.map(p => personCompact(p))
      },
    },
    'items.nl-NL.community.items[0]': {
      key: 'community',
      transform: p => p && communityCompact(p),
    },
    'containers[].body': 'stories[].name',
    'containers[].slug': 'stories[].slug',
  }

  return objectMapper(data, config)
}
