import axios from 'axios'
import loglevel from 'loglevel'
import { publicEndpoints } from './eo-api.factory'
import { createRequestInterceptors, createResponseInterceptors } from './eo-api.interceptors'
const { baseUrl, newBaseUrl, logLevel, namespace } = JSON.parse('{"baseUrl":"https://prod-api.eo.nl/v1","debug":false,"logLevel":"silent","localApiEndpoint":"/eo-api-private-endpoint","namespace":"eo-api","newBaseUrl":"https://api.eo.nl"}')
const log = loglevel.getLogger('eo-api-plugin')
log.setLevel(logLevel)

export default async ({ $bugsnag: bugsnag }, inject) => {
  const api = axios.create()
  const newApi = axios.create()
  if (baseUrl) {
    api.defaults.baseURL = baseUrl
  }
  if (newBaseUrl) {
    newApi.defaults.baseURL = newBaseUrl
  } else {
    newApi.defaults.baseURL = baseUrl
  }
  api.interceptors.request.use(...createRequestInterceptors({ bugsnag, log, namespace }))
  api.interceptors.response.use(...createResponseInterceptors({ bugsnag, log, namespace }))

  inject('api', publicEndpoints(api, newApi))
}
