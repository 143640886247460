
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_MENU_SOCIAL_ITEMS, HEADER_THEME_TABS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerMenuSocialItems: HEADER_MENU_SOCIAL_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      isMounted: false,
      isMenuOpen: false,
      collapseToggleText: {
        collapsed: "Meer thema's",
        expanded: "Minder thema's",
      },
      previousRoute: null,
    }
  },
  head: {
    title: 'Bijbel_Lab',
    titleTemplate: '%s | EO × NBG',
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs() {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
  },
  watch: {
    $route (to, from) {
      this.previousRoute = from.fullPath
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    closeMenu () {
      this.isMenuOpen = false
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    onNavigate () {
      this.isMenuOpen = false
    },
    goBack () {
      if (this.previousRoute === '/bijbel-lab') {
        this.$router.back()
      } else {
        this.$router.push('/bijbel-lab')
      }
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
