
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
    }
  },
}
