
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_THEME_TABS, FOOTER_SHORTCUTS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      footerShortcuts: FOOTER_SHORTCUTS,
      isMounted: false,
      activeTab: null,
      broadcastChannel: null,
      changedOn: null,
      environmentId: null,
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      intro: null,
      pomsId: null,
      publishOn: null,
      seo: null,
      slug: 'eo-metterdaad',
      social: null,
      subtitle: null,
      title: null,
      type: null,
    }
  },
  async fetch () {
    let program = {}

    const [
      { data: { items: programs = [] } = {} },
    ] = await Promise.all([
      // Get program for this slug
      this.$api.programs.getOneBySlug(this.slug, {
        domain: this.$config.preprEnvironmentDomainGeneriek,
      }),
    ])

    program = programs[0]
    this.broadcastChannel = program.broadcastChannel
    this.changedOn = program.changedOn
    this.environmentId = program.environmentId
    this.hero = program.hero
    this.heroVideo = program.heroVideo
    this.heroVideoStill = program.heroVideoStill
    this.id = program.id
    this.intro = program.intro
    this.pomsId = program.pomsId
    this.publishOn = program.publishOn
    this.seo = program.seo
    this.slug = program.slug
    this.social = program.social
    this.subtitle = program.subtitle
    this.title = program.title
    this.type = program.type

    // Set breadcrumbs for the page
    this.$store.dispatch('breadcrumbs/set', {
      path: `/programmas/${this.slug}`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          to: '/programmas',
          label: 'Programma\'s',
        },
        {
          label: program.title,
        },
      ],
    })
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
  },
  mounted () {
    this.isMounted = true
  },
}
