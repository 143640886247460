import { ICONS, EXTERNAL_LINKS } from '~/constants'

export const HEADER_THEME_TABS = {
  'Opgroeien & Gezin': '/thema/opgroeien-en-gezin',
  'Afscheid & Rouw': '/thema/afscheid-en-rouw',
  'Vallen & Opstaan': '/thema/vallen-en-opstaan',
  'Vieren & Herdenken': '/thema/vieren-en-herdenken',
  'Zingen & Bidden': '/thema/zingen-en-bidden',
  'Geloven & Vragen': '/thema/geloven-en-vragen',
  'Oog voor anderen': '/thema/oog-voor-anderen',
  'Hier & Nu': '/thema/hier-en-nu',
  'Natuur & Wetenschap': '/thema/natuur-en-wetenschap',
}

export const HEADER_MENU_PRIMARY_ITEMS = {
  'Opgroeien & Gezin': {
    url: HEADER_THEME_TABS['Opgroeien & Gezin'],
    icon: ICONS.TOPIC_ICON,
  },
  'Afscheid & Rouw': {
    url: HEADER_THEME_TABS['Afscheid & Rouw'],
    icon: ICONS.TOPIC_ICON,
  },
  'Vallen & Opstaan': {
    url: HEADER_THEME_TABS['Vallen & Opstaan'],
    icon: ICONS.TOPIC_ICON,
  },
  'Vieren & Herdenken': {
    url: HEADER_THEME_TABS['Vieren & Herdenken'],
    icon: ICONS.TOPIC_ICON,
  },
  'Zingen & Bidden': {
    url: HEADER_THEME_TABS['Zingen & Bidden'],
    icon: ICONS.TOPIC_ICON,
  },
  'Geloven & Vragen': {
    url: HEADER_THEME_TABS['Geloven & Vragen'],
    icon: ICONS.TOPIC_ICON,
  },
  'Oog voor anderen': {
    url: HEADER_THEME_TABS['Oog voor anderen'],
    icon: ICONS.TOPIC_ICON,
  },
  'Hier & Nu': {
    url: HEADER_THEME_TABS['Hier & Nu'],
    icon: ICONS.TOPIC_ICON,
  },
  'Natuur & Wetenschap': {
    url: HEADER_THEME_TABS['Natuur & Wetenschap'],
    icon: ICONS.TOPIC_ICON,
  },
}

export const HEADER_MENU_SECONDARY_ITEMS = {
  'Word lid': 'https://meer.eo.nl/bestel/word-eo-lid',
  'Programma\'s': '/programmas',
  // Evenementen: '/evenement',
  Podcasts: '/podcasts',
  // Magazines: '/magazine',
  'Werken bij': `${EXTERNAL_LINKS.EO_CORPORATE}/werken-bij/vacatures`,
  'Over ons': EXTERNAL_LINKS.EO_CORPORATE,
  Pers: `${EXTERNAL_LINKS.EO_CORPORATE}/pers`,
  Contact: `${EXTERNAL_LINKS.EO_CORPORATE}/contact`,
}

export const HEADER_MENU_SOCIAL_ITEMS = {
  'facebook': 'https://www.facebook.com/evangelischeomroep',
  'instagram': 'https://www.instagram.com/omroepeo/',
}

export const FOOTER_SHORTCUTS = {
  'Over de EO': {
    'Over ons': EXTERNAL_LINKS.EO_CORPORATE,
    'Werken bij': `${EXTERNAL_LINKS.EO_CORPORATE}/werken-bij/vacatures`,
    Pers: `${EXTERNAL_LINKS.EO_CORPORATE}/pers`,
    'Neem contact op': `${EXTERNAL_LINKS.EO_CORPORATE}/contact`,
  },
  'Wat wij doen': {
    'Programma\'s': '/programmas',
    // Evenementen: '/evenementen',
    Podcasts: '/podcasts',
    // Magazines: '/magazines',
  },
}
