const axios = require('axios')

const apiHost = process.env.NEW_EO_API_HOST || 'https://api.eo.nl'

export default async function ({ redirect, route }) {
  const { fullPath, path, query } = route
  if (path !== '/publication' && path !== '/publication/') {
    return
  }

  const errorPage = `/error${fullPath}`

  const {
    id,
    'model-id': modelId,
    'environment-slug': environmentSlug,
  } = query

  let {
    role,
    slug,
    topicSlug,
  } = query

  // Check if mandatory information is available
  if (!slug && !id) {
    return redirect(errorPage)
  }

  const articleRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/articles/${id}`)
      slug = pubSlug
    }

    if (environmentSlug === 'metterdaad-eo') {
      return redirect(301, `/programmas/eo-metterdaad/artikel/${slug}`)
    }

    if (environmentSlug === 'lazarus-eo') {
      return redirect(301, `/artikel/${slug}`)
    }

    return redirect(301, `/artikel/${slug}`)
  }

  const campaignRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/campaign/${id}`)
      slug = pubSlug
    }

    if (environmentSlug === 'home-eo') {
      return redirect(301, `/campagnes/${slug}`)
    }

    if (environmentSlug === 'eva-eo') {
      return redirect(301, `/nevenactiviteit/eva/campagne/${slug}`)
    }

    if (environmentSlug === 'visie-eo') {
      return redirect(301, `/nevenactiviteit/visie/campagne/${slug}`)
    }

    if (environmentSlug === 'metterdaad-eo') {
      return redirect(301, `/programmas/eo-metterdaad/projecten/${slug}`)
    }

    return // default campaign route currently doesn't exist for eo-home-webapp
  }

  const donationRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/donation/${id}`)
      slug = pubSlug
    }

    if (environmentSlug === 'metterdaad-eo') {
      return redirect(301, `/programmas/eo-metterdaad/doneren/${slug}`)
    }

    return
  }

  const subscriptionRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/subscriptions/${id}`)
      slug = pubSlug
    }
    return
  }

  const eventRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/event/${id}`)
      slug = pubSlug
    }

    if (environmentSlug === 'eva-eo') {
      return redirect(301, `/nevenactiviteit/eva/evenement/${slug}`)
    }

    if (environmentSlug === 'visie-eo') {
      return redirect(301, `/nevenactiviteit/visie/evenement/${slug}`)
    }

    if (environmentSlug === 'metterdaad-eo') {
      return redirect(301, `/programmas/eo-metterdaad/evenement/${slug}`)
    }

    return redirect(301, `/evenement/${slug}`)
  }

  const personRoute = async () => {
    if (!id && !slug) {
      return redirect(errorPage)
    }

    /**
     * Available routes for different roles
     * order in priority for people with multiple roles
     * key: slug from prepr
     * value: route in webapp
     */
    const availableRoutes = {
      presentator: '/presentatoren',
      auteur: '/redacteuren-en-gastauteurs',
      gastauteur: '/redacteuren-en-gastauteurs',
      stagiair: '/redacteuren-en-gastauteurs',
    }

    /**
     * use provided role as priority role if given and available
     */
    let route = role && availableRoutes[role]

    /**
     * Get slug or roles from api when not provided
     */
    if (!route || !slug) {
      const personIdOrQuery = id || `?slug=${slug}`

      const res = await axios.get(`${apiHost}/persons/${personIdOrQuery}`, {
        params: {
          limit: 1,
        },
      })

      const person = res?.data?.item || res?.data?.items?.[0]
      const roles = person?.roles || person?.role?.items
      if (roles) {
        const firstMatch = Object.entries(availableRoutes).find(([slug]) => {
          return roles.find(item => item.slug === slug)
        })
        if (firstMatch && firstMatch.length === 2) {
          route = firstMatch[1]
        }
      }
      slug = person?.slug
    }

    if (!route) {
      return redirect(errorPage)
    }

    return redirect(301, `${route}/${slug}`)
  }

  const podcastRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/podcasts/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/podcasts/${slug}`)
  }

  const programRoute = async () => {
    const {
      data: {
        item: {
          slug: pubSlug = {},
          type: type = '',
        } = {},
      } = {},
    } = await axios.get(`${apiHost}/programs/${id}`, {
      params: {
        aggregate: 0,
      },
    })
    slug = pubSlug

    if (type?.toLowerCase() === 'radio') {
      return redirect(301, `/radio/${slug}`)
    } else {
      return redirect(301, `/programmas/${slug}`)
    }
  }

  const pageRoute = async () => {
    if (!slug || !topicSlug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
            items,
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/pages/${id}`)
      slug = pubSlug

      // extra logic for complicated pages behind topics
      topicSlug = items?.topic?.[0]?.slug
    }

    if (environmentSlug === 'eva-eo') {
      return redirect(301, `/nevenactiviteit/eva/${slug}`)
    }

    if (environmentSlug === 'visie-eo') {
      return redirect(301, `/nevenactiviteit/visie/${slug}`)
    }

    if (environmentSlug === 'metterdaad-eo') {
      return redirect(301, `/programmas/eo-metterdaad/${slug}`)
    }

    if (topicSlug) {
      return redirect(301, `/thema/${topicSlug}/${slug}`)
    }

    return redirect(301, `/${slug}`)
  }

  const topicRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`${apiHost}/topics/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/thema/${slug}`)
  }

  if (modelId) {
    switch (modelId) {
      case '9ad0aecf-f7f9-431c-9193-52bc5bc22971': // article
        return await articleRoute()
      case 'd297be78-bf58-4270-96a0-b9aec336f952': // campaign
        return await campaignRoute()
      case 'cfe72a69-1ab5-450d-9e8f-1fb71618ecd9': // donation
        return await donationRoute()
      case '9a09ff21-e513-4c52-82fb-0736dfeaaa81': // abonnement
        return await subscriptionRoute()
      case '5abf9393-c127-488a-ac47-72917ec1c701': // event
        return await eventRoute()
      case '3e97b652-7f9f-4b47-91ef-5f63b448a983': // person
        return await personRoute()
      case 'a45ca4c5-4010-4608-8174-94354e618901': // podcast
        return await podcastRoute()
      case 'fa9af868-5af3-49db-8b55-ff1155b2a53b': // program
        return await programRoute()
      case 'eceb6d16-a6e8-439c-88d8-286c97a580ff': // page
        return await pageRoute()
      case '24f80aee-9519-4500-84e5-57553b411696': // topic
        return await topicRoute()
    }
  }

  if (slug) {
    return redirect(301, slug)
  }

  redirect(errorPage)
}
