import objectMapper from 'object-mapper'

export const organisationCompact = (data) => {
  const config = {
    'id': 'id',
    'slug': 'slug',
    'modelId': 'modelId',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environmentId': 'environmentId',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.logo.items[0].name': 'logo.name',
    'items.nl-NL.logo.items[0].cdn_files.items[0].url': 'logo.url',
    'items.nl-NL.url.body': 'url',
  }

  return objectMapper(data, config)
}