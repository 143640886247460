/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty'
import { CHANNELS, PROGRAM_ACTIONS, API_CONSTANTS } from '../constants'
import { getMutationNamesFromActions, hasMoreItemsAvailable, getRequestParams, getMutation } from '~/utils'

const channelMutations = getMutationNamesFromActions(PROGRAM_ACTIONS)

const state = () => ({
  program_featured: {},
  program_popular: {},
  program_recommended: {},
  program_missed: {},
  program_radio: {},
  program_ahead: {},
  program_new: {},
  program_awarded: {},
  program_topic_1: {},
  program_topic_2: {},
  program_topic_3: {},
  program_genre_1: {},
  program_genre_2: {},
  program_genre_3: {},
  program_genre_4: {},
  program_genre_5: {},
})

const getters = {
  program_featured: state => state.program_featured.items,
  program_popular: state => state.program_popular.items,
  program_recommended: state => state.program_recommended.items,
  program_missed: state => state.program_missed.items,
  program_radio: state => state.program_radio.items,
  program_ahead: state => state.program_ahead.items,
  program_new: state => state.program_new.items,
  program_awarded: state => state.program_awarded.items,
  program_topic_1: state => state.program_topic_1.items,
  program_topic_2: state => state.program_topic_2.items,
  program_topic_3: state => state.program_topic_3.items,
  program_genre_1: state => state.program_genre_1.items,
  program_genre_2: state => state.program_genre_2.items,
  program_genre_3: state => state.program_genre_3.items,
  program_genre_4: state => state.program_genre_4.items,
  program_genre_5: state => state.program_genre_5.items,
}

const mutations = {
  SET_PROGRAM_FEATURED: (state, payload) => {
    state.program_featured = getMutation(state.program_featured, payload)
  },
  SET_PROGRAM_POPULAR: (state, payload) => {
    state.program_popular = getMutation(state.program_popular, payload)
  },
  SET_PROGRAM_RECOMMENDED: (state, payload) => {
    state.program_recommended = getMutation(state.program_recommended, payload)
  },
  SET_PROGRAM_MISSED: (state, payload) => {
    state.program_missed = getMutation(state.program_missed, payload)
  },
  SET_PROGRAM_RADIO: (state, payload) => {
    state.program_radio = getMutation(state.program_radio, payload)
  },
  SET_PROGRAM_AHEAD: (state, payload) => {
    state.program_ahead = getMutation(state.program_ahead, payload)
  },
  SET_PROGRAM_TOPIC_1: (state, payload) => {
    state.program_topic_1 = getMutation(state.program_topic_1, payload)
  },
  SET_PROGRAM_GENRE_1: (state, payload) => {
    state.program_genre_1 = getMutation(state.program_genre_1, payload)
  },
  SET_PROGRAM_NEW: (state, payload) => {
    state.program_new = getMutation(state.program_new, payload)
  },
  SET_PROGRAM_TOPIC_2: (state, payload) => {
    state.program_topic_2 = getMutation(state.program_topic_2, payload)
  },
  SET_PROGRAM_GENRE_2: (state, payload) => {
    state.program_genre_2 = getMutation(state.program_genre_2, payload)
  },
  SET_PROGRAM_GENRE_3: (state, payload) => {
    state.program_genre_3 = getMutation(state.program_genre_3, payload)
  },
  SET_PROGRAM_AWARDED: (state, payload) => {
    state.program_awarded = getMutation(state.program_awarded, payload)
  },
  SET_PROGRAM_TOPIC_3: (state, payload) => {
    state.program_topic_3 = getMutation(state.program_topic_3, payload)
  },
  SET_PROGRAM_GENRE_4: (state, payload) => {
    state.program_genre_4 = getMutation(state.program_genre_4, payload)
  },
  SET_PROGRAM_GENRE_5: (state, payload) => {
    state.program_genre_5 = getMutation(state.program_genre_5, payload)
  },
}

const actions = {
  async proxyAction ({ dispatch, commit, state }, params) {
    if (!params?.action) {
      return
    }
    dispatch('loading/setLoading', true, { root: true })
    try {
      const storeItems = state[params.action]
      const requestParams = getRequestParams(
        storeItems?.items,
        params,
        CHANNELS,
        API_CONSTANTS.LIMIT_PROGRAMS,
        API_CONSTANTS.PREPR_DOMAIN_HOME)
      const hasNotInStore = (requestParams.offset + requestParams.limit) > storeItems?.items?.length

      if ((hasMoreItemsAvailable(storeItems) && hasNotInStore) || isEmpty(storeItems)) {
        const response = await this.$api.teasers.getAll(requestParams)
        commit(channelMutations[params.action], response.data)
      }

      dispatch('loading/setLoading', false, { root: true })
    } catch (error) {
      this.$log.warn(error)
      dispatch('loading/setLoading', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
