
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_THEME_TABS, FOOTER_SHORTCUTS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      footerShortcuts: FOOTER_SHORTCUTS,
      isMounted: false,
      activeTab: 1,
      broadcastChannel: null,
      changedOn: null,
      environmentId: null,
      genre: null,
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      intro: null,
      pomsId: null,
      publishOn: null,
      seo: null,
      slug: null,
      social: null,
      subtitle: null,
      title: null,
      type: null,

      topic: null,
      articles: [],
      episodes: [],
      content: null,
      extraContent: null,
      pomsDescription: null,
      presenters: [],

      program: null,
      showExtraContent: false,
    }
  },
  async fetch () {
    const slug = this.$route?.params?.program
    let publication = {}

    const [
      { data: { items: programs = [] } = {} },
    ] = await Promise.all([
      // Get program for this slug
      this.$api.programs.getOneBySlug(slug, {
        domain: this.$config.preprEnvironmentDomainGeneriek,
      }),
    ])

    publication = programs[0]

    this.broadcastChannel = publication.broadcastChannel
    this.changedOn = publication.changedOn
    this.environmentId = publication.environmentId
    this.genre = publication.genre
    this.hero = publication.hero
    this.heroVideo = publication.heroVideo
    this.heroVideoStill = publication.heroVideoStill
    this.id = publication.id
    this.intro = publication.intro
    this.pomsId = publication.pomsId
    this.publishOn = publication.publishOn
    this.seo = publication.seo
    this.slug = publication.slug
    this.social = publication.social
    this.subtitle = publication.subtitle
    this.title = publication.title
    this.type = publication.type

    this.pomsDescription = publication.pomsDescription
    this.content = publication.content
    this.extraContent = publication.extraContent
    this.episodes = publication.episodes
    this.topic = publication.topic
    this.presenters = publication.presenters
    this.program = publication

    if (slug) {

      // Check if we found a valid publication
      if (publication?.id) {
        // Map and filter the publication data, we only take what we need
        const program = publication

        // NOTE: It seems that the $api.programs gives a different data-structure of program episodes then
        // when we call the POMS api directly, POMS gives more data.
        const { pomsId, type } = program
        const episodes = []
        const clips = []

        if (pomsId) {
          const { data: { items: episodeItems = [] } = {} } = await this.$api.poms.playables.getAll({
            descendantOf: pomsId,
            offset: 0,
            limit: type == 'Webserie' ? 53 : 3,
            type: type == 'Webserie' ? 'CLIP' : 'BROADCAST',
          })

          if (this.isArrayFilled(episodeItems)) {
            episodes.push(...episodeItems)
          }
        }

        // Only show clips from Televisie
        if (pomsId && type == 'Televisie') {
          const { data: { items: clipItems = [] } = {} } = await this.$api.poms.playables.getAll({
            descendantOf: pomsId,
            offset: 0,
            limit: 6,
            type: 'CLIP',
          })

          clips.push(...clipItems)
        }

        const { articles: pArticles = [], total = 0 } = await this.fetchProgramArticles(this.articles.length, 4)
        this.articles.push(...pArticles)
        this.hasMoreArticlesAvailable = total > this.articles.length

        this.episodes = episodes
        this.clips = clips
      }
    }

    // Set breadcrumbs for the page
    this.$store.dispatch('breadcrumbs/set', {
      path: `/programmas/${this.slug}`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          to: '/programmas',
          label: 'Programma\'s',
        },
        {
          label: publication.title,
        },
      ],
    })
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    showAboutContent () {
      return this.isArrayFilled(this.content) || this.isArrayFilled(this.presenters) || this.type == 'Radio'
    },
  },
  watch: {
    // Workaround layout caching bug
    '$route.params': {
        handler: function() {
          if (this.$fetch) {
            this.$fetch()
          }
        },
        deep: true,
        immediate: true,
      },
  },
  created () {
    this.showExtraContent = this.showExtraContent || this.isArrayFilled(this.extraContent)
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    async fetchProgramArticles (offet = 0, limit = 6) {
      const { data: { items: pArticles = [], meta: { total = 0 } = {} } = {} } = await this.$api.programs.getAllNested(
        'articles',
        this.id,
        {
          offset: offet,
          limit: limit,
        },
      )
      if (pArticles.length > 3) {
        this.showExtraContent = true
      }

      return {
        articles: pArticles,
        total,
      }
    },
    isArrayFilled(array) {
      return array && array.length
    },
  },
}
