const state = () => ({
  selectedSubscription: null,
})

const getters = {
  selectedSubscription(state) {
    return state.selectedSubscription
  },
}

const mutations = {
  mutateSelectedSubscription(state, value) {
    state.selectedSubscription = value
  },
}

const actions = {
  updateSelectedSubscription ({ commit }, value) {
      commit('mutateSelectedSubscription', value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}