import mappers from './mappers'

/**
 * Plugin to inject a LogLevel package in the webapp
 *
 * @export
 * @param {*} context
 * @param {*} inject
 */
export default function (context, inject) {
  /**
   * Inject mappers as $map in context
   * For example ctx.$map.page(preprResponse)
   */
  inject('map', mappers)
}
