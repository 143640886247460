
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_MENU_SOCIAL_ITEMS, HEADER_THEME_TABS, FOOTER_SHORTCUTS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerMenuSocialItems: HEADER_MENU_SOCIAL_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      footerShortcuts: FOOTER_SHORTCUTS,
      isMounted: false,
    }
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
  },
  mounted () {
    this.isMounted = true
  },
}
