// get the options out using lodash templates
const { debug, levelClient } = JSON.parse('{"debug":false,"disable":false,"level":"silent","levelClient":"silent","levelServer":"warn","namespace":"log","moduleLogLevel":"warn"}')
const log = require('loglevel').getLogger('log-plugin-client')
log.setLevel(debug ? 'debug' : levelClient)

/**
 * Plugin to set logging specific for client-side
 *
 * @export
 * @param {*} context
 * @param {*} inject
 */
export default function ({ $axios, app: { router } }, inject) {
  /**
   * Inject logLevel in context (for client-only)
   * For example ctx.$logClientOnly.info('test me')
   */
  inject('logClientOnly', log)

  /**
   * Log Axios
   */
  $axios.onError((error) => {
    log.error('[axios] ', error)
  })

  /**
   * Only set loggers if log level is low enough
   */
  if (log.getLevel() <= log.levels.INFO) {
    $axios.onRequest((config) => {
      config.headers['request-startedAt'] = Date.now()
      return config
    })

    $axios.onResponse((response) => {
      const {
        config: {
          baseURL,
          params,
          headers,
          url: uri,
        } = {},
      } = response

      log.info(`[axios] Execution time: ${new Date().getTime() - headers['request-startedAt']}ms for /${uri}`)

      log.debug('[axios]', {
        baseURL,
        params,
        uri,
      })
      return response
    })

    /**
     * Log every route change as trace
     */
    router.afterEach((to, from) => {
      log.trace({
        type: 'app.router',
        to,
        from,
      })
    })
  }
}
