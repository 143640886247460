// @TODO: get values from env vars
export const CHANNELS = Object.freeze({
  PREPR_CHANNEL_CALL_TO_ACTION: process.env.PREPR_CHANNEL_CALL_TO_ACTION,
  PREPR_CHANNEL_FEATURED: process.env.PREPR_CHANNEL_FEATURED,
  PREPR_CHANNEL_POPULAR: process.env.PREPR_CHANNEL_POPULAR,
  PREPR_CHANNEL_PODCASTS: process.env.PREPR_CHANNEL_PODCASTS,
  PREPR_CHANNEL_MISSED: process.env.PREPR_CHANNEL_MISSED,
  PREPR_CHANNEL_AHEAD: process.env.PREPR_CHANNEL_AHEAD,
  PREPR_CHANNEL_RECOMMENDED: process.env.PREPR_CHANNEL_RECOMMENDED,
  PREPR_CHANNEL_EVENT: process.env.PREPR_CHANNEL_EVENT,

  PREPR_CHANNEL_TOPIC_FEATURED: process.env.PREPR_CHANNEL_TOPIC_FEATURED,
  PREPR_CHANNEL_TOPIC_NEW: process.env.PREPR_CHANNEL_TOPIC_NEW,
  PREPR_CHANNEL_TOPIC_PODCASTS: process.env.PREPR_CHANNEL_TOPIC_PODCASTS,
  PREPR_CHANNEL_TOPIC_WATCH: process.env.PREPR_CHANNEL_TOPIC_WATCH,
  PREPR_CHANNEL_TOPIC_DO: process.env.PREPR_CHANNEL_TOPIC_DO,
  PREPR_CHANNEL_TOPIC_CALL_TO_ACTION: process.env.PREPR_CHANNEL_TOPIC_CALL_TO_ACTION,
  PREPR_CHANNEL_TOPIC_RECOMMENDED: process.env.PREPR_CHANNEL_TOPIC_RECOMMENDED,
  PREPR_CHANNEL_TOPIC_SUBTOPIC_1: process.env.PREPR_CHANNEL_TOPIC_SUBTOPIC_1,
  PREPR_CHANNEL_TOPIC_SUBTOPIC_2: process.env.PREPR_CHANNEL_TOPIC_SUBTOPIC_2,
  PREPR_CHANNEL_TOPIC_SUBTOPIC_3: process.env.PREPR_CHANNEL_TOPIC_SUBTOPIC_3,
  PREPR_CHANNEL_TOPIC_SUBTOPIC_4: process.env.PREPR_CHANNEL_TOPIC_SUBTOPIC_4,
  PREPR_CHANNEL_TOPIC_SUBTOPIC_5: process.env.PREPR_CHANNEL_TOPIC_SUBTOPIC_5,
  PREPR_CHANNEL_TOPIC_SUBPAGES: process.env.PREPR_CHANNEL_TOPIC_SUBPAGES,

  PREPR_CHANNEL_PROGRAM_INFO_FEATURED: process.env.PREPR_CHANNEL_PROGRAM_INFO_FEATURED,
  PREPR_CHANNEL_PROGRAM_INFO_CALL_TO_ACTION: process.env.PREPR_CHANNEL_PROGRAM_INFO_CALL_TO_ACTION,

  PREPR_CHANNEL_PROGRAM_FEATURED: process.env.PREPR_CHANNEL_PROGRAM_FEATURED,
  PREPR_CHANNEL_PROGRAM_POPULAR: process.env.PREPR_CHANNEL_PROGRAM_POPULAR,
  PREPR_CHANNEL_PROGRAM_RECOMMENDED: process.env.PREPR_CHANNEL_PROGRAM_RECOMMENDED,
  PREPR_CHANNEL_PROGRAM_MISSED: process.env.PREPR_CHANNEL_PROGRAM_MISSED,
  PREPR_CHANNEL_PROGRAM_RADIO: process.env.PREPR_CHANNEL_PROGRAM_RADIO,
  PREPR_CHANNEL_PROGRAM_AHEAD: process.env.PREPR_CHANNEL_PROGRAM_AHEAD,
  PREPR_CHANNEL_PROGRAM_NEW: process.env.PREPR_CHANNEL_PROGRAM_NEW,
  PREPR_CHANNEL_PROGRAM_AWARDED: process.env.PREPR_CHANNEL_PROGRAM_AWARDED,
  PREPR_CHANNEL_PROGRAM_TOPIC_1: process.env.PREPR_CHANNEL_PROGRAM_TOPIC_1,
  PREPR_CHANNEL_PROGRAM_TOPIC_2: process.env.PREPR_CHANNEL_PROGRAM_TOPIC_2,
  PREPR_CHANNEL_PROGRAM_TOPIC_3: process.env.PREPR_CHANNEL_PROGRAM_TOPIC_3,
  PREPR_CHANNEL_PROGRAM_GENRE_1: process.env.PREPR_CHANNEL_PROGRAM_GENRE_1,
  PREPR_CHANNEL_PROGRAM_GENRE_2: process.env.PREPR_CHANNEL_PROGRAM_GENRE_2,
  PREPR_CHANNEL_PROGRAM_GENRE_3: process.env.PREPR_CHANNEL_PROGRAM_GENRE_3,
  PREPR_CHANNEL_PROGRAM_GENRE_4: process.env.PREPR_CHANNEL_PROGRAM_GENRE_4,
  PREPR_CHANNEL_PROGRAM_GENRE_5: process.env.PREPR_CHANNEL_PROGRAM_GENRE_5,

  PREPR_CHANNEL_PODCAST_FEATURED: process.env.PREPR_CHANNEL_PODCAST_FEATURED,
  PREPR_CHANNEL_PODCAST_POPULAR: process.env.PREPR_CHANNEL_PODCAST_POPULAR,

  PREPR_CHANNEL_JEWISH_AT_EO: process.env.PREPR_CHANNEL_JEWISH_AT_EO,
})
