const state = () => {
  return {
    queueIndex: 0,
    queue: [],
    isPlaying: false,
    isExtended: false,
    isClosed: true,
    seeker: 0,
    jumpTo: null,
  }
}

const getters = {
  queue: state => state.queue,
  selectedEpisode: state => state.queue[state.queueIndex],
  isPlaying: state => state.isPlaying,
  isExtended: state => state.isExtended,
  isClosed: state => state.isClosed,
  seeker: state => state.seeker,
  jumpTo: state => state.jumpTo,
}

const mutations = {
  ADD_TO_QUEUE: (state, podcast) => {
    state.queue.push(podcast)
    state.queueIndex = state.queue.length - 1
  },
  CLEAR_QUEUE: (state) => {
    state.queueIndex = 0
    state.queue = []
    state.isPlaying = false
  },
  UPDATE_QUEUE_INDEX: (state, index) => {
    const isValidIndex = index && !(index >= (state.queue.length - 1))
    if (isValidIndex) {
      state.queueIndex = index
    }
  },
  SET_PLAYING: (state, payload) => {
    state.isPlaying = payload
  },
  SET_EXTENDED: (state, payload) => {
    state.isExtended = payload
  },
  SET_CLOSED: (state, payload) => {
    state.isClosed = payload
  },
  SET_SEEKER: (state, payload) => {
    state.seeker = payload
  },
  SET_JUMP_TO: (state, payload) => {
    state.jumpTo = payload
  },
}

const actions = {
  addToQueue ({ commit }, podcast) {
    commit('ADD_TO_QUEUE', podcast)
  },
  clearQueue ({commit}) {
    commit('CLEAR_QUEUE')
  },
  updateQueueIndex ({ commit }, index) {
    commit('UPDATE_QUEUE_INDEX', index)
  },
  setPlaying ({ commit }, isPlaying) {
    commit('SET_PLAYING', isPlaying)
  },
  setExtended ({ commit }, isExtended) {
    commit('SET_EXTENDED', isExtended)
  },
  setClosed ({ commit }, isClosed) {
    commit('SET_CLOSED', isClosed)
  },
  setSeeker ({ commit }, value) {
    commit("SET_SEEKER", value)
  },
  setJumpTo ({ commit }, value) {
    commit("SET_JUMP_TO", value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
