import objectMapper from 'object-mapper'
import { campaignCompact } from './campaign'
import { podcastCompact } from './podcast'
import { programCompact } from './program'

export const donationCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
  }

  return objectMapper(data, config)
}

export const donation = (data) => {
  const optionConfig = {
    'items.amount.value': 'amount',
    'items.description.body': 'description',
    'id': 'id',
  }

  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.conclusion.items[]': 'conclusion[]',
    'items.nl-NL.once_sf_id.body': 'once.salesforceId',
    'items.nl-NL.once_convince_social.body': 'once.convinceSocial',
    'items.nl-NL.once_convince_personal.body': 'once.convincePersonal',
    'items.nl-NL.once_justification.body': 'once.justification',
    'items.nl-NL.once_options.items[]': {
      key: 'once.options',
      transform: (options) => {
        if (!options || !options.length) return null
        return options.map(o => objectMapper(o, optionConfig))
      },
    },
    'items.nl-NL.once_newsletter.items[0].id': 'once.newsletter',
    'items.nl-NL.once_newsletter_convince.body': 'once.newsletterConvince',
    'items.nl-NL.once_campaign.items[0]': {
      key: 'once.campaign',
      transform: p => p && campaignCompact(p),
    },
    'items.nl-NL.once_program.items[0]': {
      key: 'once.program',
      transform: p => p && programCompact(p),
    },
    'items.nl-NL.once_podcast.items[0]': {
      key: 'once.podcast',
      transform: p => p && podcastCompact(p),
    },
    'items.nl-NL.monthly_sf_id.body': 'monthly.salesforceId',
    'items.nl-NL.monthly_convince_social.body': 'monthly.convinceSocial',
    'items.nl-NL.monthly_convince_personal.body': 'monthly.convincePersonal',
    'items.nl-NL.monthly_justification.body': 'monthly.justification',
    'items.nl-NL.monthly_options.items[]': {
      key: 'monthly.options',
      transform: (options) => {
        if (!options || !options.length) return null
        return options.map(o => objectMapper(o, optionConfig))
      },
    },
    'items.nl-NL.monthly_newsletter.items[0].id': 'monthly.newsletter',
    'items.nl-NL.monthly_newsletter_convince.body': 'monthly.newsletterConvince',
    'items.nl-NL.monthly_campaign.items[0]': {
      key: 'monthly.campaign',
      transform: p => p && campaignCompact(p),
    },
    'items.nl-NL.monthly_program.items[0]': {
      key: 'monthly.program',
      transform: p => p && programCompact(p),
    },
    'items.nl-NL.monthly_podcast.items[0]': {
      key: 'monthly.podcast',
      transform: p => p && podcastCompact(p),
    },
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
  }
  return objectMapper(data, config)
}
