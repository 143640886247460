import objectMapper from 'object-mapper'

export const communityCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.summary.body': 'summary',
    'items.nl-NL.logo.items[0].cdn_files.items[0].url': 'logo',
    'items.nl-NL.avatar.items[0].cdn_files.items[0].url': 'avatar',
    'items.nl-NL.prepr_environment_id.body': 'environmentId',
  }

  return objectMapper(data, config)
}

export const community = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.name.body': 'name',
    'items.nl-NL.summary.body': 'summary',
    'items.nl-NL.logo.items[0].cdn_files.items[0].url': 'logo',
    'items.nl-NL.avatar.items[0].cdn_files.items[0].url': 'avatar',
    'items.nl-NL.prepr_environment_id.body': 'environmentId',
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
  }

  return objectMapper(data, config)
}
