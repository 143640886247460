/**
 * Convert Axios Error into log and/or bugsnag report
 */
const handleError = ({ bugsnag, log, namespace }, error, source) => {
  if (!error) {
    return
  }

  const {
    config: {
      baseURL,
      data: configData,
      method,
      params,
      url,
    } = {},
    message,
    response: {
      data = {},
      status,
    } = {},
  } = error

  const errorMessage = `${source}: ${data.message || message}`
  const severity =  status === 404 ? 'Warning' : 'Error'
  const context = `@eo/nuxt-eo-api: ${url}`
  const debugInfo = {
    source,
    message,
    url,
    config: {
      baseURL,
      data: configData,
      method,
      params,
      url,
    },
    data,
  }

  if (log) {
    log.info(`[${namespace}] DEBUG bugsnag.notify`, {
      errorMessage,
      onErrorFunction: {
        severity,
        context,
        debugInfo,
      },
    })
    log.debug({ error })
  }

  if (bugsnag) {
    bugsnag.notify(new Error(errorMessage), (e) => {
      e.severity = severity
      e.context = context
      e.addMetadata('debug info', debugInfo)
    })
  }

  return {
    status,
    errorMessage,
  }
}

/**
 * Docs: https://axios-http.com/docs/interceptors
 * returns two function to use as interceptors
 * axios.interceptors.request.use(() => {}, () => {})
 */
export const createRequestInterceptors = (loggers) => {
  return [
    config => config,
    (error) => {
      return handleError(loggers, error, 'eo-api.request-interceptor')
    },
  ]
}

/**
 * Docs: https://axios-http.com/docs/interceptors
 * * returns two function to use as interceptors
 * axios.interceptors.response.use(() => {}, () => {})
 */
export const createResponseInterceptors = (loggers) => {
  return [
    response => response,
    (error) => {
      return handleError(loggers, error, 'eo-api.response-interceptor')
    },
  ]
}
