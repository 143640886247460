const state = () => ({
  selectedSingleSale: null,
})

const getters = {
  selectedSingleSale(state) {
    return state.selectedSingleSale
  },
}

const mutations = {
  mutateSelectedSingleSale(state, value) {
    state.selectedSingleSale = value
  },
}

const actions = {
  updateSelectedSingleSale ({ commit }, value) {
    commit('mutateSelectedSingleSale', value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}