const state = () => ({
  ccm: {
    isCcmDone: false,
    permissions: [],
  },
})

const getters = {
  getCcmPermissions: state => state.ccm.permissions,
  isCcmDone: state => state.ccm.isCcmDone,
}

const mutations = {
  SET_CCM_PERMISSION (state, permission) {
    state.ccm.permissions.push(permission)
  },
  SET_CCM_DONE (state, val) {
    state.ccm.isCcmDone = val
  },
}

const actions = {
  setCcmPermission ({ commit }, permission) {
    commit('SET_CCM_PERMISSION', permission)
  },
  setCcmDone ({ commit }, status) {
    commit('SET_CCM_DONE', status)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
