import axios from 'axios'
import loglevel from 'loglevel'
import { privateEndpoints } from './eo-api.factory'
import { createRequestInterceptors, createResponseInterceptors } from './eo-api.interceptors'
const { localApiEndpoint, logLevel, namespace } = JSON.parse('{"baseUrl":"https://prod-api.eo.nl/v1","debug":false,"logLevel":"silent","localApiEndpoint":"/eo-api-private-endpoint","namespace":"eo-api","newBaseUrl":"https://api.eo.nl"}')
const log = loglevel.getLogger('eo-api-plugin-private.client')
log.setLevel(logLevel)

export default async ({ $bugsnag: bugsnag }, inject) => {
  const api = axios.create({
    baseURL: localApiEndpoint,
  })

  api.interceptors.request.use(...createRequestInterceptors({ bugsnag, log, namespace }))
  api.interceptors.response.use(...createResponseInterceptors({ bugsnag, log, namespace }))

  inject('privateApi', privateEndpoints(api))
}
