import isEmpty from 'lodash/isEmpty'
import { CHANNELS, PODCAST_ACTIONS, API_CONSTANTS } from '../constants'
import { getMutationNamesFromActions, hasMoreItemsAvailable, getRequestParams, getMutation } from '~/utils'

const channelMutations = getMutationNamesFromActions(PODCAST_ACTIONS)

const state = () => ({
  podcast_featured: {},
  podcast_popular: {},
})

const getters = {
  podcast_featured: state => state.podcast_featured.items,
  podcast_popular: state => state.podcast_popular.items,
}

const mutations = {
  SET_PODCAST_FEATURED: (state, payload) => {
    state.podcast_featured = getMutation(state.podcast_featured, payload)
  },
  SET_PODCAST_POPULAR: (state, payload) => {
    state.podcast_popular = getMutation(state.podcast_popular, payload)
  },
}

const actions = {
  async proxyAction ({ dispatch, commit, state }, params) {
    if (!params?.action) {
      return
    }
    dispatch('loading/setLoading', true, { root: true })
    try {
      const storeItems = state[params.action]
      const requestParams = getRequestParams(
        storeItems?.items,
        params,
        CHANNELS,
        API_CONSTANTS.LIMIT_PODCASTS,
        API_CONSTANTS.PREPR_DOMAIN_HOME)
      const hasNotInStore = (requestParams.offset + requestParams.limit) > storeItems?.items?.length

      if ((hasMoreItemsAvailable(storeItems) && hasNotInStore) || isEmpty(storeItems)) {
        const response = await this.$api.teasers.getAll(requestParams)
        commit(channelMutations[params.action], response.data)
      }

      dispatch('loading/setLoading', false, { root: true })
    } catch (error) {
      this.$log.warn(error)
      dispatch('loading/setLoading', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
