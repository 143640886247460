import isEmpty from 'lodash/isEmpty'
import { CHANNELS, API_CONSTANTS, TOPIC_ACTIONS, TOPICS } from '~/constants'
import { hasInStoreForTopic, getRequestParams, getMutation, getMutationNamesFromActions, filterItemsByTopic } from '~/utils'

const topicChannelMutations = getMutationNamesFromActions(TOPIC_ACTIONS)

const state = () => ({
  topic_call_to_action: {},
  topic_do: {},
  topic_featured: {},
  topic_new: {},
  topic_podcasts: {},
  topic_recommended: {},
  topic_subtopic_1: {},
  topic_subtopic_2: {},
  topic_subtopic_3: {},
  topic_subtopic_4: {},
  topic_subtopic_5: {},
  topic_subpages: {},
  topic_watch: {},
})

const getters = {
  topic_call_to_action: state => topic => filterItemsByTopic(state.topic_call_to_action.items, topic),
  topic_do: state => topic => filterItemsByTopic(state.topic_do.items, topic),
  topic_new: state => topic => filterItemsByTopic(state.topic_new.items, topic),
  topic_featured: state => topic => filterItemsByTopic(state.topic_featured.items, topic),
  topic_podcasts: state => topic => filterItemsByTopic(state.topic_podcasts.items, topic),
  topic_recommended: state => topic => filterItemsByTopic(state.topic_recommended.items, topic),
  topic_subtopic_1: state => topic => filterItemsByTopic(state.topic_subtopic_1.items, topic),
  topic_subtopic_2: state => topic => filterItemsByTopic(state.topic_subtopic_2.items, topic),
  topic_subtopic_3: state => topic => filterItemsByTopic(state.topic_subtopic_3.items, topic),
  topic_subtopic_4: state => topic => filterItemsByTopic(state.topic_subtopic_4.items, topic),
  topic_subtopic_5: state => topic => filterItemsByTopic(state.topic_subtopic_5.items, topic),
  topic_subpages: state => topic => filterItemsByTopic(state.topic_subpages.items, topic),
  topic_watch: state => topic => filterItemsByTopic(state.topic_watch.items, topic),
}

const mutations = {
  SET_TOPIC_CALL_TO_ACTION: (state, payload) => {
    state.topic_call_to_action = getMutation(state.topic_call_to_action, payload)
  },
  SET_TOPIC_DO: (state, payload) => {
    state.topic_do = getMutation(state.topic_do, payload)
  },
  SET_TOPIC_FEATURED: (state, payload) => {
    state.topic_featured = getMutation(state.topic_featured, payload)
  },
  SET_TOPIC_NEW: (state, payload) => {
    state.topic_new = getMutation(state.topic_new, payload)
  },
  SET_TOPIC_PODCASTS: (state, payload) => {
    state.topic_podcasts = getMutation(state.topic_podcasts, payload)
  },
  SET_TOPIC_RECOMMENDED: (state, payload) => {
    state.topic_recommended = getMutation(state.topic_recommended, payload)
  },
  SET_TOPIC_SUBTOPIC_1: (state, payload) => {
    state.topic_subtopic_1 = getMutation(state.topic_subtopic_1, payload)
  },
  SET_TOPIC_SUBTOPIC_2: (state, payload) => {
    state.topic_subtopic_2 = getMutation(state.topic_subtopic_2, payload)
  },
  SET_TOPIC_SUBTOPIC_3: (state, payload) => {
    state.topic_subtopic_3 = getMutation(state.topic_subtopic_3, payload)
  },
  SET_TOPIC_SUBTOPIC_4: (state, payload) => {
    state.topic_subtopic_4 = getMutation(state.topic_subtopic_4, payload)
  },
  SET_TOPIC_SUBTOPIC_5: (state, payload) => {
    state.topic_subtopic_5 = getMutation(state.topic_subtopic_5, payload)
  },
  SET_TOPIC_SUBPAGES: (state, payload) => {
    state.topic_subpages = getMutation(state.topic_subpages, payload)
  },
  SET_TOPIC_WATCH: (state, payload) => {
    state.topic_watch = getMutation(state.topic_watch, payload)
  },
}

const actions = {
  /**
   * Gets teasers from a channel based on the action name and offset/limit params and stores them by committing the result
   * @param {Object} params The action name that will determine the prepr channel and the limit and offset of the query
   */
  //
  async proxyAction ({ dispatch, commit, state }, params = {}) {
    const topicId = params.topicId ? params.topicId : TOPICS[params.topic].id
    let response = {}
    if (!params.action || !topicId) {
      return
    }
    dispatch('loading/setLoading', true, { root: true })
    try {
      const channelState = state[params.action]
      const requestParams = getRequestParams(
        channelState?.items,
        params,
        CHANNELS,
        API_CONSTANTS.LIMIT_TEASERS,
        API_CONSTANTS.PREPR_DOMAIN_HOME)
      // TODO track meta-data per topic to only fetch when more items on server
      const hasInStore = hasInStoreForTopic(channelState?.items, params)
      if (!hasInStore || isEmpty(channelState)) {
        response = await this.$api.topics.getAllNested('teasers', topicId, requestParams)
        commit(topicChannelMutations[params.action], response.data)
      }
      dispatch('loading/setLoading', false, { root: true })
      return response.status
    } catch (error) {
      this.$log.warn(error)
      dispatch('loading/setLoading', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
