import objectMapper from 'object-mapper'
import { communityCompact } from './community'
import { episodeCompact } from './episode'
import { organisationCompact } from './organisation'
import { personCompact } from './person'
import { tagCompact } from './tag'
import { topicCompact } from './topic'

export const channelToIcon = (broadcastChannel) => {
  if (!broadcastChannel) {
    return null
  }
  switch (broadcastChannel) {
    case 'NED1':
      return 'npo-1'
    case 'NED2':
      return 'npo-2'
    case 'NED3':
      return 'npo-3'
    case 'RAD1':
      return 'npo-radio-1'
    case 'RAD2':
      return 'npo-radio-2'
    case 'RAD3':
      return 'npo-3fm'
    case 'RAD4':
      return 'npo-radio-4'
    case 'RAD5':
      return 'npo-radio-5'
    case 'EO':
      return 'npo-1'
    case 'VPRO':
      return 'npo-2'
    case 'BNNVARA':
      return 'npo-3'
  }
  return null
}

export const programCompact = (data) => {
  const config = {
    'id': 'id',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'slug.nl-NL': 'slug',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.type.body': 'type',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.community.items[0]': {
      key: 'community',
      transform: p => p && communityCompact(p),
    },
    'items.nl-NL.poms.items.[0].id': 'pomsId',
  }

  return objectMapper(data, config)
}

export const program = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.hero_video.items[0].cdn_files.items[0].url': 'heroVideo',
    'items.nl-NL.hero_video.items[0].cover.cdn_files.items[0].url': 'heroVideoStill',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.content.items[]': 'content[]',
    'items.nl-NL.extra_content.items[]': 'extraContent[]',
    'items.nl-NL.press_content.items[]': 'pressContent[]',
    'items.nl-NL.genre.body': 'genreName',
    'items.nl-NL.genre.id': 'genreId',
    'items.nl-NL.poms.items.[0].id': 'pomsId',
    'items.nl-NL.poms.items.[0].description': 'pomsDescription',
    'items.nl-NL.poms.items.[0].data.lastEpisodes.items[0].channel': {
      key: 'broadcastChannel',
      transform: channelToIcon,
    },
    'items.nl-NL.poms.items[0].data.lastEpisodes.items[]': {
      key: 'episodes',
      transform: (episodes) => {
        if (!episodes || !episodes.length) {
          return null
        }
        return episodes.map(e => episodeCompact(e))
      },
    },
    'items.nl-NL.presenter.items[]': {
      key: 'presenters',
      transform: (persons) => {
        if (!persons || !persons.length) {
          return null
        }
        return persons.map(p => personCompact(p))
      },
    },
    'items.nl-NL.contact.items[]': {
      key: 'contacts',
      transform: (persons) => {
        if (!persons || !persons.length) {
          return null
        }
        return persons.map(p => personCompact(p))
      },
    },
    'items.nl-NL.press_downloads.items': {
      key: 'downloads',
      transform: items => (items && {
        images: items.filter(d => d.label === 'Photo'),
        others: items.filter(d => d.label !== 'Photo'),
      }),
    },
    'items.nl-NL.type.body': 'type',
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.community.items[0]': {
      key: 'community',
      transform: p => p && communityCompact(p),
    },
    'items.nl-NL.tags.items[]': {
      key: 'tags',
      transform: (tags) => {
        if (!tags || !tags.length) {
          return null
        }
        return tags.map(p => tagCompact(p))
      },
    },
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
    'items.nl-NL.sponsor.items[]': {
      key: 'sponsors',
      transform: (org) => {
        if (!org || !org.length) {
          return null
        }
        return org.map(p => organisationCompact(p))
      },
    },
    'items.nl-NL.partner.items[]': {
      key: 'partners',
      transform: (org) => {
        if (!org || !org.length) {
          return null
        }
        return org.map(p => organisationCompact(p))
      },
    },
    'items.nl-NL.participator.items[]': {
      key: 'participators',
      transform: (org) => {
        if (!org || !org.length) {
          return null
        }
        return org.map(p => organisationCompact(p))
      },
    },
  }

  return objectMapper(data, config)
}
