// get the options out using lodash templates
const { debug, level, levelClient, levelServer } = JSON.parse('{"debug":false,"disable":false,"level":"silent","levelClient":"silent","levelServer":"warn","namespace":"log","moduleLogLevel":"warn"}')
const loglevel = require('loglevel')
const log = loglevel.getLogger('log-injected')
const logClientOnly = loglevel.getLogger('log-plugin-client')
const logServerOnly = loglevel.getLogger('log-plugin-server')
const faker = {
  trace: () => {},
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
}
log.setLevel(debug ? 'debug' : level || 'silent')
logClientOnly.setLevel(debug ? 'debug' : levelClient)
logServerOnly.setLevel(debug ? 'debug' : levelServer)

log.clientOnly = process.client && logClientOnly || faker
log.serverOnly = process.server && logServerOnly || faker

/**
 * Plugin to inject a LogLevel package in the webapp
 *
 * @export
 * @param {*} context
 * @param {*} inject
 */
export default function ({}, inject) {
  /**
   * Inject logLevel in context
   * For example ctx.$log.info('test me')
   * For example ctx.$log.clientOnly.info('test me')
   * For example ctx.$log.serverOnly.info('test me')
   */
  inject('log', log)
}
