
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_MENU_SOCIAL_ITEMS, HEADER_THEME_TABS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerMenuSocialItems: HEADER_MENU_SOCIAL_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      keyword: null,
      isMounted: false,
      isMenuOpen: false,
      activeTab: 0,
      broadcasterLogo: this.headerBroadcasterLogo,
      collapseToggleText: {
        collapsed: "Meer thema's",
        expanded: "Minder thema's",
      },
      hero: 'https://eva-eo.cdn.eo.nl/{format}/s3-eva-eo/64764578-ce4a-4570-926e-c6713fcb9958.jpg',
      description: '',
      events: null,
      campaigns: null,
    }
  },
  async fetch () {
    const {
      data: {
        item: community = {},
      } = {},
    } = await this.$api.communities.getOne(this.$config.preprCommunityIdEva)

    if (community) {
      if (community.hero)  {
        this.hero = community.hero
      }
      this.description = community.description
      const { data: { items: events = [] } = {} } = await this.$api.events.getAll({ domain: this.$config.preprEnvironmentDomainEva })
      this.events = events
      const { data: { items: campaigns = [] } = {} } = await this.$api.campaigns.getAll({ domain: this.$config.preprEnvironmentDomainEva })
      this.campaigns = campaigns
    }
 
    this.$store.dispatch('breadcrumbs/set', {
      path: '/nevenactiviteit/eva',
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          label: 'Eva',
        },
      ],
    })
  },
  head: {
    title: 'Home',
    titleTemplate: '%s - EO Eva',
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    closeMenu () {
      this.isMenuOpen = false
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    onNavigate () {
      this.isMenuOpen = false
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
