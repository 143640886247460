import Vue from 'vue'
import { decode, parsePath, withoutBase, withoutTrailingSlash, normalizeURL } from 'ufo'

import { getMatchedComponentsInstances, getChildrenComponentInstancesUsingFetch, promisify, globalHandleError, urlJoin, sanitizeComponent } from './utils'
import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../node_modules/@eo/component-library/src/styles/base/_index.scss'

import '../node_modules/@eo/component-library/src/styles/helpers/_index.scss'

import '../node_modules/@eo/component-library/src/styles/animations/_index.scss'

import '../node_modules/@eo/nuxt-cookie-consent/lib/styling.scss'

import _6dd9d3c5 from '../layouts/bijbellab-minimal.vue'
import _e010381e from '../layouts/bijbellab.vue'
import _204a1024 from '../layouts/bijbelse-namen.vue'
import _59637aee from '../layouts/campaign-eva.vue'
import _39b1bbb5 from '../layouts/campaign-visie.vue'
import _6610f1c4 from '../layouts/campaign-zorgmedewerker.vue'
import _4f573706 from '../layouts/campaign.vue'
import _9f9fb13c from '../layouts/community-eva-content.vue'
import _3a3225b6 from '../layouts/community-eva.vue'
import _772987a2 from '../layouts/community-visie.vue'
import _6f6c098b from '../layouts/default.vue'
import _c033c632 from '../layouts/event-eva.vue'
import _590a14ae from '../layouts/event-metterdaad.vue'
import _3fbc7693 from '../layouts/event-visie.vue'
import _7746f064 from '../layouts/event.vue'
import _2b880c31 from '../layouts/fullscreen.vue'
import _5c202036 from '../layouts/game-containerdorp.vue'
import _870eeb40 from '../layouts/hokjes-experiment.vue'
import _7793f483 from '../layouts/kerst.vue'
import _2b8fa9d0 from '../layouts/minimal-dark.vue'
import _52cbc0eb from '../layouts/minimal.vue'
import _2fa8dc5a from '../layouts/niet-alleen.vue'
import _77d89a95 from '../layouts/pasen.vue'
import _5ef73963 from '../layouts/pinksteren.vue'
import _0aa18d04 from '../layouts/program-metterdaad.vue'
import _00e751ce from '../layouts/program.vue'
import _77f491c5 from '../layouts/radio.vue'

const layouts = { "_bijbellab-minimal": sanitizeComponent(_6dd9d3c5),"_bijbellab": sanitizeComponent(_e010381e),"_bijbelse-namen": sanitizeComponent(_204a1024),"_campaign-eva": sanitizeComponent(_59637aee),"_campaign-visie": sanitizeComponent(_39b1bbb5),"_campaign-zorgmedewerker": sanitizeComponent(_6610f1c4),"_campaign": sanitizeComponent(_4f573706),"_community-eva-content": sanitizeComponent(_9f9fb13c),"_community-eva": sanitizeComponent(_3a3225b6),"_community-visie": sanitizeComponent(_772987a2),"_default": sanitizeComponent(_6f6c098b),"_event-eva": sanitizeComponent(_c033c632),"_event-metterdaad": sanitizeComponent(_590a14ae),"_event-visie": sanitizeComponent(_3fbc7693),"_event": sanitizeComponent(_7746f064),"_fullscreen": sanitizeComponent(_2b880c31),"_game-containerdorp": sanitizeComponent(_5c202036),"_hokjes-experiment": sanitizeComponent(_870eeb40),"_kerst": sanitizeComponent(_7793f483),"_minimal-dark": sanitizeComponent(_2b8fa9d0),"_minimal": sanitizeComponent(_52cbc0eb),"_niet-alleen": sanitizeComponent(_2fa8dc5a),"_pasen": sanitizeComponent(_77d89a95),"_pinksteren": sanitizeComponent(_5ef73963),"_program-metterdaad": sanitizeComponent(_0aa18d04),"_program": sanitizeComponent(_00e751ce),"_radio": sanitizeComponent(_77f491c5) }

export default {
  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: '',

    nbFetching: 0
    }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    this.$root.$options.$nuxt = this

    if (process.client) {
      // add to window so we can listen when ready
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  async mounted () {
    this.$loading = this.$refs.loading
  },

  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    },

    isFetching () {
      return this.nbFetching > 0
    },
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map(async (page) => {
        let p = []

        // Old fetch
        if (page.$options.fetch && page.$options.fetch.length) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        // Wait for asyncData & old fetch to finish
        await Promise.all(p)
        // Cleanup refs
        p = []

        if (page.$fetch) {
          p.push(page.$fetch())
        }
        // Get all component instance to call $fetch
        for (const component of getChildrenComponentInstancesUsingFetch(page.$vnode.componentInstance)) {
          p.push(component.$fetch())
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail(error)
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },
    errorChanged () {
      if (this.nuxt.err) {
        if (this.$loading) {
          if (this.$loading.fail) {
            this.$loading.fail(this.nuxt.err)
          }
          if (this.$loading.finish) {
            this.$loading.finish()
          }
        }

        let errorLayout = (NuxtError.options || NuxtError).layout;

        if (typeof errorLayout === 'function') {
          errorLayout = errorLayout(this.context)
        }

        this.setLayout(errorLayout)
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    },
  },

  components: {
    NuxtLoading
  }
}
