import {
  legacy as legacyFunctions,
  basic as basicFunctions,
  containerdorp as containerdorpFunctions,
  hokjesexperiment as hokjesexperimentFunctions,
  interaction as interactionFunctions,
  mailing as mailingFunctions,
  mixedarticles as mixedarticlesFunctions,
  npo as npoFunctions,
  payments as paymentsFunctions,
  polls as pollsFunctions,
  poms as pomsFunctions,
  queue as queueFunctions,
  recommendations as recommendationsFunctions,
  crm as crmFunctions,
  songsdb as songsdbFunctions,
  recruitment as recruitmentFunctions,
  invoices as invoicesFunctions,
  user as userFunctions,
} from './eo-api.functions'

export const publicEndpoints = (api, newApi) => ({
  articles: legacyFunctions(newApi, 'articles'),
  calltoactions: legacyFunctions(newApi, 'calltoactions'),
  campaigns: legacyFunctions(newApi, 'campaigns'),
  candles: legacyFunctions(newApi, 'candles'),
  communities: legacyFunctions(newApi, 'communities'),
  containerdorp: containerdorpFunctions(newApi, 'containerdorp'),
  contentselections: legacyFunctions(newApi, 'contentselections'),
  courses: legacyFunctions(newApi, 'courses'),
  courseparts: legacyFunctions(newApi, 'courseparts'),
  crm: crmFunctions(newApi, 'salesforce'),
  donations: basicFunctions(newApi, 'donations'),
  events: legacyFunctions(newApi, 'events'),
  forms: legacyFunctions(newApi, 'forms'),
  hokjesexperiment: hokjesexperimentFunctions(newApi, 'hokjesexperiment'),
  interaction: interactionFunctions(newApi, 'interaction'),
  invoices: invoicesFunctions(newApi, 'salesforce/invoices'),
  mailform: legacyFunctions(api, 'mailform'),
  mixedarticles: mixedarticlesFunctions(newApi, 'mixedarticles'),
  npo: npoFunctions(api, 'npo'),
  pages: legacyFunctions(newApi, 'pages'),
  payments: paymentsFunctions(newApi, 'payment'),
  persons: legacyFunctions(newApi, 'persons'),
  playlists: legacyFunctions(newApi, 'playlists'),
  podcasts: legacyFunctions(newApi, 'podcasts'),
  polls: pollsFunctions(newApi, 'polls'),
  poms: pomsFunctions(newApi, 'poms'),
  pressreleases: legacyFunctions(newApi, 'pressreleases'),
  programs: legacyFunctions(newApi, 'programs'),
  publications: legacyFunctions(newApi, 'publications'),
  recommendations: recommendationsFunctions(newApi, 'recommendations'),
  recruitment: recruitmentFunctions(newApi, 'recruitment'),
  related: legacyFunctions(newApi, 'related'),
  salesforce: {
    ...legacyFunctions(newApi, 'salesforce'),
    ...queueFunctions(newApi, 'salesforce'),
  },
  sections: legacyFunctions(newApi, 'sections'),
  songs: legacyFunctions(newApi, 'songs'),
  songsdb: songsdbFunctions(newApi, 'songsdb'),
  songselections: legacyFunctions(newApi, 'songselections'),
  stories: legacyFunctions(newApi, 'stories'),
  subscriptions: basicFunctions(newApi, 'subscriptions'),
  subscriptionSelections: basicFunctions(newApi, 'subscriptionselections'),
  singleSales: basicFunctions(newApi, 'singleSales'),
  tags: legacyFunctions(newApi, 'tags'),
  teasers: legacyFunctions(newApi, 'teasers'),
  topics: legacyFunctions(newApi, 'topics'),
  user: userFunctions(newApi, 'user'),
  mailings: mailingFunctions(newApi),
})

export const privateEndpoints = (api, newApi) => ({
  mailings: mailingFunctions(newApi),
  salesforce: queueFunctions(newApi, 'salesforce'),
})
