import objectMapper from 'object-mapper'
import { eventCompact } from './event'
import { campaignCompact } from './campaign'
import { personCompact } from './person'
import { podcastCompact } from './podcast'
import { programCompact } from './program'
import { tagCompact } from './tag'
import { topicCompact } from './topic'

export const articleCompact = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
    'items.nl-NL.author.items[0]': {
      key: 'author',
      transform: p => p && personCompact(p),
    },
    'items.nl-NL.type.body': 'type',
  }

  return objectMapper(data, config)
}

export const article = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.subtitle.body': 'subtitle',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.featured_video.items[]': 'featuredVideo[]',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.content.items[]': 'content[]',
    'items.nl-NL.type.body': 'type',
    'items.nl-NL.location.latitude': 'location.latitude',
    'items.nl-NL.location.longitude': 'location.longitude',
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
    'items.nl-NL.event.items[0]': {
      key: 'event',
      transform: p => p && eventCompact(p),
    },
    'items.nl-NL.person.items[]': {
      key: 'persons',
      transform: (persons) => {
        if (!persons || !persons.length) {
          return null
        }
        return persons.map(p => personCompact(p))
      },
    },
    'items.nl-NL.program.items[0]': {
      key: 'program',
      transform: p => p && programCompact(p),
    },
    'items.nl-NL.podcast.items[0]': {
      key: 'podcast',
      transform: p => p && podcastCompact(p),
    },
    'items.nl-NL.campaign.items[0]': {
      key: 'campaign',
      transform: p => p && campaignCompact(p),
    },
    'items.nl-NL.author.items[0]': {
      key: 'author',
      transform: p => p && personCompact(p),
    },
    'items.nl-NL.tags.items[]': {
      key: 'tags',
      transform: (tags) => {
        if (!tags || !tags.length) {
          return null
        }
        return tags.map(p => tagCompact(p))
      },
    },
    'containers[].body': 'stories[].name',
    'containers[].slug': 'stories[].slug',
  }

  return objectMapper(data, config)
}
