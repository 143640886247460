import cookieConsentStore from './cookie-consent.store'

// get the options out using lodash templates
const { fakePermissions, logLevel, namespace } = JSON.parse('{"ccmWrapperVersion":"v1.1","ccmDomain":"https://cookies.eo.nl","debug":false,"disable":false,"domain":"eo.nl","fakePermissions":null,"logLevel":"warn","namespace":"cookie-consent","organisation":"EO"}')
const log = require('loglevel').getLogger('cookie-consent-plugin')
log.setLevel(logLevel)

export default async ({ store }) => {
  let permissions = []

  /**
   * Register cookie-consent store in global Vuex store
   */
  await store.registerModule('cookieConsent', cookieConsentStore, {
    preserveState: !!process.browser, // Keep track of this value, maybe someday this will trigger an error -Martijn.
  })

  if (fakePermissions && fakePermissions.length) {
    log.warn(`[${namespace}] Faking CCM permissions with these values: `, fakePermissions)
    permissions = fakePermissions.split(',')
    store.dispatch('cookieConsent/setPermissions', permissions)
    return
  }
}
