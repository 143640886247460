import Vue from 'vue'
import podcastPlayerStore from './podcast-player.store'

/**
 * Make <eo-podcast-player> component available throughout Nuxt
 */
Vue.component('EoPodcastPlayer', () => import('@eo/nuxt-podcast-player/lib/EoPodcastPlayer/EoPodcastPlayer.vue'))

export default async ({ store }) => {
  /**
   * Register a store for podcast player in Nuxt
   */
  await store.registerModule('podcastPlayer', podcastPlayerStore, {
    preserveState: false,
  })
}
