
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_MENU_SOCIAL_ITEMS, HEADER_THEME_TABS, FOOTER_SHORTCUTS, PREPR_TOPIC_TITLES } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerMenuSocialItems: HEADER_MENU_SOCIAL_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      footerShortcuts: FOOTER_SHORTCUTS,
      articlesOpgroeienEnGezin: null,
      articlesVallenEnOpstaan: null,
      isMounted: false,
    }
  },
  async fetch () {
    const [
      { data: { items: articlesVallenEnOpstaan = [] } = {} },
      { data: { items: articlesOpgroeienEnGezin = [] } = {} },
    ] = await Promise.all([
      this.$api.topics.getAllNested('articles', PREPR_TOPIC_TITLES['vallen-en-opstaan'].id, {
        domain: this.$config.preprEnvironmentDomainEo,
        limit: 3,
      }),
      this.$api.topics.getAllNested('articles', PREPR_TOPIC_TITLES['opgroeien-en-gezin'].id, {
        domain: this.$config.preprEnvironmentDomainEo,
        limit: 3,
      }),
    ])

    this.articlesVallenEnOpstaan = articlesVallenEnOpstaan
    this.articlesOpgroeienEnGezin = articlesOpgroeienEnGezin
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
  },
  mounted () {
    this.isMounted = true
  },
}
