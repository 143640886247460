import objectMapper from 'object-mapper'

const playlistProviders = (publicationElementId) => {
  if (!publicationElementId) {
    return null
  }
  switch (publicationElementId) {
    case 'b69cea69-15ea-437d-8deb-8b73e7902c64': // Afspeelbare Youtube content
      return 'youtube'
    case '2fcf1264-7fa9-4015-85b5-abc14101f00c': // Afspeelbare Vimeo content
      return 'vimeo'
    case '35dc825a-458b-4212-b986-22f60187ff27': // Afspeelbare NPO content
      return 'npo'
  }
  return publicationElementId
}

export const playlist = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.description.body': 'description',
    'items.nl-NL.playlist.items[].items.title.body': 'media[].title',
    'items.nl-NL.playlist.items[].items.description.body': 'media[].description',
    'items.nl-NL.playlist.items[].items.youtube.url': 'media[].id',
    'items.nl-NL.playlist.items[].items.vimeo.url': 'media[].id',
    'items.nl-NL.playlist.items[].items.npo_media.items[0].id': 'media[].id',
    'items.nl-NL.playlist.items[].items.npo_media.items[0].image_url': 'media[].poster',
    'items.nl-NL.playlist.items[].id': {
      key: 'media[].provider',
      transform: playlistProviders,
    },
  }
  return objectMapper(data, config)
}

export const playlistEpisodes = (data) => {
  const config = {
    'imageUrl': 'poster',
    'mainTitle': 'title',
    'sortDate': 'publishOn',
    'mid': [
      'id',
      {
        key: 'provider',
        transform: () => 'npo',
      },
    ],
  }
  return data.map(episode => objectMapper(episode, config))
}
