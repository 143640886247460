/* eslint-disable camelcase */
// TODO: Use json.select for mapping
import { EXTERNAL_LINKS, shareLinks } from '~/constants'
import { slugify } from '~/utils'

export const podcastUrl = (podcast) => {
  const slug = podcast.slug ? podcast.slug : slugify(podcast.title)
  return `${EXTERNAL_LINKS.EO_HOME}/podcasts/${slug}`
}

export const podcastEpisodesUrl = (podcast) => {
  return `${podcastUrl(podcast)}/alle-afleveringen`
}

const podcastEpisodeUrl = (podcast, episode) => {
  return shareLinks.API_WHATSAPP_SHARE
  + podcastEpisodesUrl(podcast) + '?'
  + (episode.id
    ? `id=${encodeURIComponent(episode.id)}`
    : `title=${slugify(episode.title)}`)
}


export const mapFeedToEpisodes = (p, podcast = null) => {
  return p.items.map((episode) => {
    return {
      podcast: podcast ? podcast : {
        ...(p?.title && { slug: slugify(p.title) }),
        ...(p.title && { title: p.title }),
        ...(p.image?.url && { imageUrl: p.image.url }),
        ...(p?.title && { sharingUrl: podcastUrl(p) }),
        ...(p?.title && { episodesUrl: podcastEpisodesUrl(p) }),
      },
      npo: {
        ...(episode?.id && { id: episode.id }),
        ...(episode?.title && { slug: slugify(episode.title) }),
        ...(episode?.title && { title: episode.title }),
        ...(episode?.title && podcast?.title && { shareWhatsAppUrl: podcastEpisodeUrl(podcast, episode) }),
        ...(episode?.content && { description: episode.content }),
        ...(episode?.content && { content: episode.content }),
        ...(episode?.itunes?.duration && { duration: episode.itunes.duration }),
        ...(episode?.enclosure?.url && { enclosure: { url: episode.enclosure.url } }),
        ...(episode?.pubDate && !isNaN(Date.parse(episode?.pubDate)) && { pubDate: new Date(episode.pubDate) }),
      },
    }
  })
}

export const mapPlayablesToEpisodes = (p) => {
  return p.map((episode) => {
    return {
      ...episode,
      ...(episode?.id && { id: episode.mid }),
      ...(episode?.subTitle && { title: episode.subTitle }),
    }
  })
}