import objectMapper from 'object-mapper'

export const tagCompact = (data) => {
  const config = {
    'id': 'id',
    'slug': 'slug',
    'modelId': 'modelId',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environmentId': 'environmentId',
    'environment.id': 'environmentId',
    'body': 'name',
    'name': 'title',
    'tag': 'slug',
  }

  return objectMapper(data, config)
}

export const tag = (data) => {
  const config = {
    'id': 'id',
    'slug': 'slug',
    'modelId': 'modelId',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environmentId': 'environmentId',
    'environment.id': 'environmentId',
    'body': 'name',
    'name': 'title',
    'seoDescription': 'seo.description',
    'seoTitle': 'seo.title',
    'socialDescription': 'social.description',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'socialTitle': 'social.title',
    'tag': 'slug',
  }

  return objectMapper(data, config)
}
