import { article, articleCompact } from './article'
import { campaign, campaignCompact } from './campaign'
import { community, communityCompact } from './community'
import { donation, donationCompact } from './donation'
import { episode, episodeCompact, episodeFromSchedule } from './episode'
import { event, eventCompact } from './event'
import { organisationCompact } from './organisation'
import { page, pageCompact } from './page'
import { person, personCompact } from './person'
import { playlist, playlistEpisodes } from './playlist'
import { pressRelease, pressReleaseCompact } from './press-release'
import { podcast, podcastCompact } from './podcast'
import { program, programCompact } from './program'
import { tag, tagCompact } from './tag'
import { teaserCompact } from './teaser'
import { topic, topicCompact } from './topic'

export default {
  article: data => article(data),
  articles: data => data.map(x => articleCompact(x)),

  campaign: data => campaign(data),
  campaigns: data => data.map(x => campaignCompact(x)),

  community: data => community(data),
  communities: data => data.map(x => communityCompact(x)),

  donation: data => donation(data),
  donations: data => data.map(x => donationCompact(x)),

  episode: data => episode(data),
  episodes: data => data.map(x => episodeCompact(x)),
  episodesFromSchedule: data => data.map(x => episodeFromSchedule(x)),

  event: data => event(data),
  events: data => data.map(x => eventCompact(x)),

  organisation: data => organisationCompact(data),
  organisations: data => data.map(x => organisationCompact(x)),

  page: data => page(data),
  pages: data => data.map(x => pageCompact(x)),

  person: data => person(data),
  persons: data => data.map(x => personCompact(x)),

  playlist: data => playlist(data),
  playlistEpisodes: data => playlistEpisodes(data),

  podcast: data => podcast(data),
  podcasts: data => data.map(x => podcastCompact(x)),

  pressRelease: data => pressRelease(data),
  pressReleases: data => data.map(x => pressReleaseCompact(x)),

  program: data => program(data),
  programs: data => data.map(x => programCompact(x)),

  tag: data => tag(data),
  tags: data => data.map(x => tagCompact(x)),

  teaser: data => teaserCompact(data),
  teasers: data => data.map(x => teaserCompact(x)),

  topic: data => topic(data),
  topics: data => data.map(x => topicCompact(x)),
}
