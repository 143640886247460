import objectMapper from 'object-mapper'
import { channelToIcon } from './program'

export const episodeCompact = (data) => {
  const config = {
    'start': {
      key: 'publishOn',
      transform: milliseconds => milliseconds / 1000,
    },
    'midRef': 'id',
    'media.type': 'type',
    'media.titles': {
      key: 'title',
      transform: (titles) => {
        const title = titles && titles.find(d => d.type === 'SUB')
        return title && title.value
      },
    },
    'media.descriptions': [
      {
        key: 'description',
        transform: (descriptions) => {
          const description = descriptions && descriptions.find(d => d.type === 'MAIN')
          return description && description.value
        },
      },
      {
        key: 'intro',
        transform: (descriptions) => {
          const description = descriptions && descriptions.find(d => d.type === 'SHORT')
          return description && description.value
        },
      },
    ],
    'media.images': {
      key: 'hero',
      transform: (images) => {
        const image = images && (images.find(i => i.type === 'PROMO_LANDSCAPE' || i.type === 'PICTURE' || i.type === 'STILL') || images[0])
        if (image && image.imageUri) {
          return 'https://images.poms.omroep.nl/image/' + image.imageUri.split(':').pop() + '.jpg'
        }
      },
    },
  }

  return objectMapper(data, config)
}

export const episode = (data) => {
  const config = {
    'channel': {
      key: 'broadcastChannel',
      transform: channelToIcon,
    },
    'start': {
      key: 'publishOn',
      transform: milliseconds => milliseconds / 1000,
    },
    'midRef': 'id',
    'media.type': 'type',
    'media.avType': 'avType',
    'media.sortDate': 'sortDate',
    'media.lastModified': 'changedOn',
    'media.broadcasters': 'broadcasters',
    'media.titles': {
      key: 'title',
      transform: (titles) => {
        const title = titles && titles.find(d => d.type === 'SUB')
        return title && title.value
      },
    },
    'media.descriptions': [
      {
        key: 'description',
        transform: (descriptions) => {
          const description = descriptions && descriptions.find(d => d.type === 'MAIN')
          return description && description.value
        },
      },
      {
        key: 'intro',
        transform: (descriptions) => {
          const description = descriptions && descriptions.find(d => d.type === 'SHORT')
          return description && description.value
        },
      },
    ],
    'media.images': {
      key: 'hero',
      transform: (images) => {
        const image = images && images.find(i => i.type === 'PROMO_LANDSCAPE' || i.type === 'PICTURE')
        if (image && image.imageUri) {
          return 'https://images.poms.omroep.nl/image/' + image.imageUri.split(':').pop() + '.jpg'
        }
      },
    },
    'media.scheduleEvents': 'scheduleEvents',
    'media.publishDate': 'publishDate',
  }

  return objectMapper(data, config)
}

export const episodeFromSchedule = (data) => {
  const config = {
    'id': 'id',
    'title': 'title',
    'start': 'nextOnAir',
    'duration': 'duration',
    'broadcaster': 'broadcaster',
    'channel': {
      key: 'broadcastChannel',
      transform: channelToIcon,
    },
    'net': 'net',
    'image': 'hero',
    'program': 'pomsId',
  }

  const p = objectMapper(data, config)

  if (!p.hero) {
    p.hero = 'https://6n0gcpuqtebh.b-cdn.net/{format}/s3-generieke-content-eo/f2e0867f-7ac1-4886-a883-dd60174b9127.jpg'
  }

  return p
}
