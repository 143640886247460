export default {
  head() {
    const canonical = this.$config.baseUrlCanonical + this.$route.path

    // Create the return object
    let head = {
      title: '',
      meta: [
        { hid: 'og:url', property: 'og:url', content: canonical },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      ],
      link: [
        { rel: 'canonical', href: canonical },
      ],
      script: [],
    }

    // Collect and merge global variables
    let tags = []
    let category
    let type
    const modelId = this.model?.id || this.modelId
    const modelName = this.model?.name || this.modelName || this.$library?.prepr.models[modelId]?.name
    const genre = this.genre?.name || this.genre || this.genreName
    const seo = this.seo ?? {}
    const social = this.social ?? {}
    let {
      title: seoTitle,
      description: seoDescription,
      allowIndex: seoAllowIndex,
    } = seo
    let {
      title: socialTitle,
      description: socialDescription,
      image: socialImage,
    } = social
    seoTitle = seoTitle ?? this.title ?? this.name
    seoDescription = seoDescription ?? this.intro ?? this.description
    socialTitle = socialTitle ?? seoTitle
    socialDescription = socialDescription ?? seoDescription
    socialImage = socialImage ?? this.heroUrl ?? this.hero
    if (socialImage && typeof socialImage === 'object' && socialImage.items) {
      /* eslint-disable-next-line camelcase */
      socialImage = socialImage.items[0]?.cdn_files?.items?.[0]?.url
    }

    // Metadata for SEO
    if (seoDescription) {
      head.meta.push({ hid: 'description', name: 'description', content: seoDescription })
    }
    if (seoAllowIndex === false) {
      head.meta.push({ hid: 'robots', name: 'robots', content: 'noindex, nofollow, noarchive, noimageindex' })
    }
    if (socialTitle) {
      head.meta.push({ hid: 'og:title', property: 'og:title', content: socialTitle })
      head.meta.push({ hid: 'twitter:title', name: 'twitter:title', content: socialTitle })
    }
    if (socialDescription) {
      head.meta.push({ hid: 'og:description', property: 'og:description', content: socialDescription })
      head.meta.push({ hid: 'twitter:description', name: 'twitter:description', content: socialDescription })
    }
    if (socialImage) {
      socialImage = socialImage.replace('.png', '.jpg').replace('{format}', 'w_1200')
      head.meta.push({ hid: 'og:image', property: 'og:image', content: socialImage })
      head.meta.push({ hid: 'twitter:image', name: 'twitter:image', content: socialImage })
    }

    // Metadata for Site Search 360
    if (modelName) {
      switch (modelName) {
        case 'Programma':
        case 'program':
          type = 'Programma'
          category = this.type === 'Radio' ? 'Luisteren' : 'Kijken'
          head.meta.push({
            hid: 'program-type',
            content: this.type ?? 'Televisie',
          })
          break
        case 'Podcast':
        case 'podcast':
          type = this.type ?? 'Podcast'
          category = this.category ?? 'Luisteren'
          break
        case 'Campagne':
        case 'campaign':
          type = this.type ?? 'Campagne'
          category = this.category ?? 'Doen'
          break
        case 'Evenement':
        case 'event':
          type = this.type ?? 'Evenement'
          category = this.category ?? 'Doen'
          break
        case 'Contentpagina':
        case 'page':
          type = this.type ?? 'Pagina'
          category = this.category ?? 'Lezen'
          break
        case 'Persbericht':
        case 'pressrelease':
          type = this.type ?? 'Persbericht'
          category = this.category ?? 'Lezen'
          break
        case 'Artikel':
        case 'article':
          type = this.type ?? 'Artikel'
          category = this.category ?? 'Lezen'
          break
        case 'Persoon':
        case 'person':
          if (this.roles?.length) {
            tags.push(...this.roles.map(role => role.name || role.body))
            type = this.type ?? this.roles.map(role => role.name || role.body)[0]
          } else {
            type = this.type ?? 'Persoon'
          }
          break
        default:
          category = this.category ?? 'Lezen'
      }
    }
    if (type) {
      head.meta.push({
        hid: 'type',
        content: type,
      })
    }
    if (category) {
      head.meta.push({
        hid: 'category',
        content: category,
      })
    }
    if (genre) {
      head.meta.push({
        hid: 'genre',
        content: genre,
      })
    }
    if (this.topic?.name) {
      head.meta.push({
        hid: 'topic',
        content: this.topic.name,
      })
    }

    // Metadata for Site Search 360 (Bijbelse Namen)
    if (this.environmentId === 'a8a439b2-c424-4c10-99c2-b674af18c173') {
      if (this.stories) {
        for (let story of this.stories) {
          switch (story.name) {
            case 'populaire meisjesnamen':
              head.meta.push({
                hid: 'Populaire bijbelsenamen',
                content: 'Meisjesnamen',
              })
              break
            case 'populaire jongensnamen':
              head.meta.push({
                hid: 'Populaire bijbelsenamen',
                content: 'Jongensnamen',
              })
              break
            case 'meisjesnamen':
              head.meta.push({
                hid: 'Bijbelsenamen meisje jongen',
                content: 'Meisjesnamen',
              })
              break
            case 'jongensnamen':
              head.meta.push({
                hid: 'Bijbelsenamen meisje jongen',
                content: 'Jongensnamen',
              })
              break
            case 'Hebreeuws':
              head.meta.push({
                hid: 'Bijbelsenamen taal',
                content: 'Hebreeuws',
              })
              break
            case 'Grieks':
              head.meta.push({
                hid: 'Bijbelsenamen taal',
                content: 'Grieks',
              })
              break
            case 'nieuwe testament':
              head.meta.push({
                hid: 'Bijbelsenamen testament',
                content: 'Nieuwe',
              })
              break
            case 'oude testament':
              head.meta.push({
                hid: 'Bijbelsenamen testament',
                content: 'Oude',
              })
              break
            default:
              break
          }
        }
      }
    }

    // Metadata for Prepr Analytics
    if (this.id) {
      head.meta.push({
        property: 'prepr:id',
        content: this.id,
      })
    }
    if (this.$auth.loggedIn) {
      const { user } = this.$auth
      head.meta.push({
        hid: 'prepr-customer-id',
        property: 'prepr:customer-id',
        content: user['https://mijn.eo.nl/preprCustomerId'],
      })
    }

    // Metadata for Piano
    if (this.tags) {
      tags.push(...this.tags.map(tag => tag.name))
    }
    if (this.id) {
      head.meta.push({
        hid: 'cXenseParse:eva-publication_id',
        name: 'cXenseParse:eva-publication_id',
        content: this.id,
      })
    }
    if (modelName) {
      head.meta.push({
        hid: 'cXenseParse:eva-publication_type',
        name: 'cXenseParse:eva-publication_type',
        content: modelName,
      })
    }
    if (modelId) {
      head.meta.push({
        hid: 'cXenseParse:eva-publication_type_id',
        name: 'cXenseParse:eva-publication_type_id',
        content: modelId,
      })
    }
    if (this.publishOn) {
      head.meta.push({
        hid: 'cXenseParse:time:eva-publication_date',
        name: 'cXenseParse:time:eva-publication_date',
        content: this.publishOn,
      })
    }
    if (this.readTime) {
      head.meta.push({
        hid: 'cXenseParse:number:eva-publication_readtime',
        name: 'cXenseParse:number:eva-publication_readtime',
        content: this.readTime,
      })
    }
    if (this.topic?.name) {
      head.meta.push({
        hid: 'cXenseParse:eva-publication_topic',
        name: 'cXenseParse:eva-publication_topic',
        content: this.topic.name,
      })
    }
    if (this.topic?.id) {
      head.meta.push({
        hid: 'cXenseParse:eva-publication_topic_id',
        name: 'cXenseParse:eva-publication_topic_id',
        content: this.topic.id,
      })
    }
    if (tags.length) {
      head.meta.push({
        hid: 'cXenseParse:eva-publication_tags',
        name: 'cXenseParse:eva-publication_tags',
        content: tags.join(','),
      })
    }

    // Get overwrites and additions from context (this.head)
    if (this.head) {
      const {
        meta: metaAdditions,
        link: linkAdditions,
        ...otherOverwrites
      } = this.head

      if (metaAdditions) {
        head.meta = head.meta.concat(metaAdditions)
      }
      if (linkAdditions) {
        head.link = head.link.concat(linkAdditions)
      }
      if (otherOverwrites) {
        head = {
          ...head,
          ...otherOverwrites,
        }
      }
    }

    if (!head.title) {
      head.title = seoTitle || socialTitle
    }

    return head
  },
}
