import constants from '../config/constants'

const state = () => ({
  topics: {},
})

const getters = {
  topics: state => state.topics.items,
}

const mutations = {
  SET_TOPICS: (state, payload) => {
    state.topics = payload
  },

  ADD_TOPIC: (state, payload) => {
    state.topics.items.push(payload)
  },
}

const actions = {
  async proxyAction ({ dispatch, commit, state }, params = {}) {
    dispatch('loading/setLoading', true, { root: true })
    try {
      if (!params.id) { // If you want to retreive all topics
        const hasAllTopicsInStore = state.topics?.meta?.total <= state.topics?.items?.length
        if (!hasAllTopicsInStore) {
          const response = await this.$api.topics.getAll(
            { offset: 0, domain: constants.PREPR_ENVIRONMENT_DOMAIN_GENERIEK })
          commit('SET_TOPICS', response.data)
        }
      } else { // If you want a single topic and topic is not yet in the store
        const hasInStore = state.topics.items.find(topic => topic.id === params.model)
        if (!hasInStore) {
          const response = await this.$api.topics.getOne(params.model)
          commit('ADD_TOPIC', response.data.items[0])
        }
      }
      dispatch('loading/setLoading', false, { root: true })
    } catch (error) {
      this.$log.warn(error)
      dispatch('loading/setLoading', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
