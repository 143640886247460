export const PREPR_MODELS = Object.freeze({
  PREPR_API_MODEL_ID_ARTICLES: process.env.PREPR_API_MODEL_ID_ARTICLES,
  PREPR_API_MODEL_ID_CTA: process.env.PREPR_API_MODEL_ID_CTA,
  PREPR_API_MODEL_ID_EVENTS: process.env.PREPR_API_MODEL_ID_EVENTS,
  PREPR_API_MODEL_ID_FORMS: process.env.PREPR_API_MODEL_ID_FORMS,
  PREPR_API_MODEL_ID_PAGES: process.env.PREPR_API_MODEL_ID_PAGES,
  PREPR_API_MODEL_ID_PERSONS: process.env.PREPR_API_MODEL_ID_PERSONS,
  PREPR_API_MODEL_ID_PODCASTS: process.env.PREPR_API_MODEL_ID_PODCASTS,
  PREPR_API_MODEL_ID_PROGRAMS: process.env.PREPR_API_MODEL_ID_PROGRAMS,
  PREPR_API_MODEL_ID_PRESSRELEASES: process.env.PREPR_API_MODEL_ID_PRESSRELEASES,
  PREPR_API_MODEL_ID_TEASERS: process.env.PREPR_API_MODEL_ID_TEASERS,
  PREPR_API_MODEL_ID_TOPICS: process.env.PREPR_API_MODEL_ID_TOPICS,
})

export const PREPR_MODEL_NAMES = Object.freeze({
  [PREPR_MODELS.PREPR_API_MODEL_ID_ARTICLES]: 'articles',
  [PREPR_MODELS.PREPR_API_MODEL_ID_EVENTS]: 'events',
  [PREPR_MODELS.PREPR_API_MODEL_ID_FORMS]: 'forms',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PAGES]: 'pages',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PERSONS]: 'persons',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PODCASTS]: 'podcasts',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PROGRAMS]: 'programs',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PRESSRELEASES]: 'pressreleases',
  [PREPR_MODELS.PREPR_API_MODEL_ID_TEASERS]: 'teasers',
  [PREPR_MODELS.PREPR_API_MODEL_ID_TOPICS]: 'topics',
})

export const PREPR_TOPICS = Object.freeze({
  PREPR_API_TOPIC_ID_OPGROEIEN_EN_GEZIN: process.env.PREPR_API_TOPIC_ID_OPGROEIEN_EN_GEZIN,
  PREPR_API_TOPIC_ID_AFSCHEID_EN_ROUW: process.env.PREPR_API_TOPIC_ID_AFSCHEID_EN_ROUW,
  PREPR_API_TOPIC_ID_VALLEN_EN_OPSTAAN: process.env.PREPR_API_TOPIC_ID_VALLEN_EN_OPSTAAN,
  PREPR_API_TOPIC_ID_VIEREN_EN_HERDENKEN: process.env.PREPR_API_TOPIC_ID_VIEREN_EN_HERDENKEN,
  PREPR_API_TOPIC_ID_ZINGEN_EN_BIDDEN: process.env.PREPR_API_TOPIC_ID_ZINGEN_EN_BIDDEN,
  PREPR_API_TOPIC_ID_GELOVEN_EN_VRAGEN: process.env.PREPR_API_TOPIC_ID_GELOVEN_EN_VRAGEN,
  PREPR_API_TOPIC_ID_OOG_VOOR_ANDEREN: process.env.PREPR_API_TOPIC_ID_OOG_VOOR_ANDEREN,
  PREPR_API_TOPIC_ID_HIER_EN_NU: process.env.PREPR_API_TOPIC_ID_HIER_EN_NU,
  PREPR_API_TOPIC_ID_NATUUR_EN_WETENSCHAP: process.env.PREPR_API_TOPIC_ID_NATUUR_EN_WETENSCHAP,
})

export const TOPICS = Object.freeze({
  'hier-en-nu': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_HIER_EN_NU, title: 'Hier & Nu' },
  'vieren-en-herdenken': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_VIEREN_EN_HERDENKEN, title: "Vieren & Herdenken"},
  'opgroeien-en-gezin': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_OPGROEIEN_EN_GEZIN, title: "Opgroeien & Gezin"},
  'vallen-en-opstaan': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_VALLEN_EN_OPSTAAN, title: "Vallen & Opstaan"},
  'oog-voor-anderen': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_OOG_VOOR_ANDEREN, title: "Oog voor anderen"},
  'natuur-en-wetenschap': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_NATUUR_EN_WETENSCHAP, title: "Natuur & Wetenschap"},
  'zingen-en-bidden': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_ZINGEN_EN_BIDDEN, title: "Zingen & Bidden"},
  'geloven-en-vragen': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_GELOVEN_EN_VRAGEN, title: "Geloven & Vragen"},
  'afscheid-en-rouw': { id: PREPR_TOPICS.PREPR_API_TOPIC_ID_AFSCHEID_EN_ROUW, title: "Afscheid & Rouw"},
})

export const MODEL_PATHS = Object.freeze({
  [PREPR_MODELS.PREPR_API_MODEL_ID_PAGES]: '/{{slug}}',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PERSONS]: '/{{role}}/{{slug}}',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PROGRAMS]: '/programmas/{{slug}}',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PODCASTS]: '/podcasts/{{slug}}',
  [PREPR_MODELS.PREPR_API_MODEL_ID_ARTICLES]: '/artikel/{{slug}}',
  [PREPR_MODELS.PREPR_API_MODEL_ID_PRESSRELEASES]: process.env.BASE_URL_EO_CORPORATE + 'pers/{{slug}}',
  [PREPR_MODELS.PREPR_API_MODEL_ID_TOPICS]: '/thema/{{slug}}',
})

export const DYNAMIC_PAGE_PATHS = Object.freeze({
  topic: '/thema/{{slug}}',
  program: '/programmas/{{slug}}',
})

export const PREPR_TOPIC_TITLES = Object.freeze({
  'hier-en-nu':           {id: '44fc7b66-58f4-4c19-bc9d-d23c012a9d32', title: "Hier & Nu"},
  'vieren-en-herdenken':  {id: '6b84b7b8-e017-4ef4-887f-7415135cf1f9', title: "Vieren & Herdenken"},
  'opgroeien-en-gezin':   {id: '0f250998-c9f9-4267-ba33-1688f075f49c', title: "Opgroeien & Gezin"},
  'vallen-en-opstaan':    {id: 'e4fa52bf-be3c-406d-8da6-1dae5ac5877f', title: "Vallen & Opstaan"},
  'oog-voor-anderen':     {id: 'c2c21d66-85ae-45d3-b88c-142b099ce398', title: "Oog voor anderen"},
  'natuur-en-wetenschap': {id: 'd5c28c8d-d36d-4359-8a3c-dbf6ae4bd5b8', title: "Natuur & Wetenschap"},
  'zingen-en-bidden':     {id: 'a9821f05-bd4d-4284-bf35-6525f6d9cb26', title: "Zingen & Bidden"},
  'geloven-en-vragen':    {id: 'e5d460f5-a2c7-453b-b31e-fb06e951e410', title: "Geloven & Vragen"},
  'afscheid-en-rouw':     {id: 'dc00af95-d5ba-4c44-b998-f8551f35b63c', title: "Afscheid & Rouw"},
})
