/* eslint-disable */
const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
const p = new RegExp(a.split('').join('|'), 'g')

export const slugify = (text) => {
  return text ? text.toString().toLowerCase()
    .replace(/\s+/g, '-')                     // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c)))  // Replace special characters
    .replace(/&/g, '-and-')                   // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')                 // Remove all non-word characters
    .replace(/\-\-+/g, '-')                   // Replace multiple - with single -
    .replace(/^-+/, '')                       // Trim - from start of text
    .replace(/-+$/, '')                       // Trim - from end of text
    : null
}

export const isValidSlug = (slug = '') => {
  // return true if slug contains a combination of letters, numbers, slashes and parameters (?id=value || &id=value)
  var pattern = new RegExp('^[a-zA-Z0-9/-]+((/?|/&)([^=]+)\=([^&]+))*')
  return slug.length > 0 && !!pattern.test(slug)
}

export const cleanSlug = (slug = '') => {
  return slug.replace(/^\/|\/$/g, '') // remove leading and trailing slashes
}

/**
 * Filters out special characters and only shows letters and numbers
 * @param {*} slug 
 * @param {*} prefix 
 * @returns String
 */

 export const simpleSlug = (slug = '', prefix = '-') => {
  return slug.replace(/[^a-zA-Z0-9]/g, prefix)
}