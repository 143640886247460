/**
 * New place to store constants that are used on several places.
 * When a constant is only used in one place, don't use this file, but just directly inject the constant where it needs to be.
 * This to avoid clutter and old constants from old projects.
 */
export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.BITBUCKET_TAG || `v0.0.0-rc.${process.env.BITBUCKET_BUILD_NUMBER}`,
  BASE_URL: (process.env.BITBUCKET_PR_ID && `https://pr-${process.env.BITBUCKET_PR_ID}-eo-home.feat.eo.nl`) || process.env.BASE_URL || 'https://www.eo.nl',
  BASE_URL_CANONICAL: process.env.BASE_URL_CANONICAL || 'https://www.eo.nl',
  EO_API_HOST: process.env.EO_API_HOST || 'https://tst-api.eo.nl',
  NEW_EO_API_HOST: process.env.NEW_EO_API_HOST || 'https://tst-api.eo.nl',
  EO_API_VERSION: 'v1',
  PREPR_ENVIRONMENT_DOMAIN_BIJBELSENAMEN: process.env.PREPR_ENVIRONMENT_DOMAIN_BIJBELSENAMEN || 'bijbelsenamen-eo',
  PREPR_ENVIRONMENT_DOMAIN_EO: process.env.PREPR_ENVIRONMENT_DOMAIN_EO || 'home-eo',
  PREPR_ENVIRONMENT_DOMAIN_EVA: process.env.PREPR_ENVIRONMENT_DOMAIN_EVA || 'eva-eo',
  PREPR_ENVIRONMENT_DOMAIN_GENERIEK: process.env.PREPR_ENVIRONMENT_DOMAIN_GENERIEK || 'generieke-content-eo',
  PREPR_ENVIRONMENT_DOMAIN_METTERDAAD: process.env.PREPR_ENVIRONMENT_DOMAIN_METTERDAAD || 'metterdaad-eo',
  PREPR_ENVIRONMENT_DOMAIN_VISIE: process.env.PREPR_ENVIRONMENT_DOMAIN_VISIE || 'visie-eo',
  PREPR_ENVIRONMENT_DOMAIN_LAZARUS: process.env.PREPR_ENVIRONMENT_DOMAIN_LAZARUS || 'lazarus-eo',
  PREPR_ENVIRONMENT_ID_EO: '94dcecf2-1725-436c-9205-04f20bcffef0',
  PREPR_ENVIRONMENT_ID_EVA: '3fc256c0-b074-4780-b401-20e562827537',
  PREPR_ENVIRONMENT_ID_METTERDAAD: '48c62830-e604-48fa-bc4b-693a8962d21e',
  PREPR_ENVIRONMENT_ID_VISIE: 'c21bea32-65a1-4b4b-a9d8-5f4544b4ff05',
  PREPR_ENVIRONMENT_ID_LAZARUS: '55177197-688f-4dde-8d1f-509f61babbe7',
  PREPR_COMMUNITY_ID_EO: process.env.PREPR_COMMUNITY_ID_EO || '78915b89-f177-440a-8f8f-62dfea174d62',
  PREPR_COMMUNITY_ID_EVA: process.env.PREPR_COMMUNITY_ID_EVA || 'ef6cd8a6-9fcd-49e0-85c2-03fdd38a710b',
  PREPR_COMMUNITY_ID_METTERDAAD: process.env.PREPR_COMMUNITY_ID_METTERDAAD || '52e68b37-0573-49a5-b7bd-ad6f5c061878',
  PREPR_COMMUNITY_ID_VISIE: process.env.PREPR_COMMUNITY_ID_VISIE || '943f11ab-2031-4eda-b77d-0ae968c4518b',
  PLAYER_KEY: process.env.PLAYER_KEY,
  PLAYER_ANALYTICS_KEY: process.env.PLAYER_ANALYTICS_KEY,
  TRACKING_FIELD_HOME: 'home',
  TRACKING_FIELD_EO: 'eo',
  TRACKING_FIELD_EVA: 'eva',
  TRACKING_FIELD_METTERDAAD: 'metterdaad',
  TRACKING_FIELD_VISIE: 'visie',
  STORIES_BIJBELSE_NAMEN: {
    'beginletter-a': {
      id: '2eda32df-5f0b-47d7-811d-5ed923887c26',
      label: 'A',
      type: 'letter',
    },
    'beginletter-b': {
      id: '2b08587b-107d-443e-8be9-d2d3b4410bbf',
      label: 'B',
      type: 'letter',
    },
    'beginletter-c': {
      id: 'accd774b-f70e-456e-8b4c-24a4ea17c0f8',
      label: 'C',
      type: 'letter',
    },
    'beginletter-d': {
      id: '094891e9-6d97-40d9-baca-183422c160e2',
      label: 'D',
      type: 'letter',
    },
    'beginletter-e': {
      id: '1b1b98cc-1c55-436b-a92f-ce06596e0f5b',
      label: 'E',
      type: 'letter',
    },
    'beginletter-f': {
      id: '33f8c1c7-4fce-4b77-bf7a-2ff1b98cc87a',
      label: 'F',
      type: 'letter',
    },
    'beginletter-g': {
      id: 'f95e82a5-c6d3-437f-a392-16a6d670857a',
      label: 'G',
      type: 'letter',
    },
    'beginletter-h': {
      id: '5be8f22f-0a8c-469b-bf91-6e7fece7a3a0',
      label: 'H',
      type: 'letter',
    },
    'beginletter-i': {
      id: '0a4e5188-7642-4373-ac3f-dc1377f44117',
      label: 'I',
      type: 'letter',
    },
    'beginletter-j': {
      id: 'f491d2de-a5b8-45c6-8cc1-341fa3276525',
      label: 'J',
      type: 'letter',
    },
    'beginletter-k': {
      id: '6f3c0dc5-36bd-4e86-b966-de2b21a32732',
      label: 'K',
      type: 'letter',
    },
    'beginletter-l': {
      id: 'aec9c4f8-ddf3-4409-adfd-68d9dd7f77d1',
      label: 'L',
      type: 'letter',
    },
    'beginletter-m': {
      id: '5c830c45-e60a-40a0-a348-39604aab425a',
      label: 'M',
      type: 'letter',
    },
    'beginletter-n': {
      id: '3b1c6141-a1eb-4091-85f1-94ee2e91b3d3',
      label: 'N',
      type: 'letter',
    },
    'beginletter-o': {
      id: '69bdaab3-5b77-4001-8fca-1e2ec6ffac02',
      label: 'O',
      type: 'letter',
    },
    'beginletter-p': {
      id: '39870a72-aa07-49ac-8214-83a51f04becb',
      label: 'P',
      type: 'letter',
    },
    'beginletter-q': {
      id: '40e0062e-2207-4371-b881-6497eb1dea62',
      label: 'Q',
      type: 'letter',
    },
    'beginletter-r': {
      id: '80693af9-6fe6-4c8f-9538-572bb86ad024',
      label: 'R',
      type: 'letter',
    },
    'beginletter-s': {
      id: 'a9d3bd81-cf5c-437b-9a94-b2195a3da940',
      label: 'S',
      type: 'letter',
    },
    'beginletter-t': {
      id: 'd5f6fb04-67c4-4819-bce1-d03440ddbf8b',
      label: 'T',
      type: 'letter',
    },
    'beginletter-u': {
      id: '53d05bdb-28ef-4f23-92e6-b2b6c55e1dcd',
      label: 'U',
      type: 'letter',
    },
    'beginletter-v': {
      id: 'e7056ef9-99ba-463f-aa35-4d9d5226d21e',
      label: 'V',
      type: 'letter',
    },
    'beginletter-w': {
      id: 'd153acd3-e560-4ed2-8ca3-963ee1a60c66',
      label: 'W',
      type: 'letter',
    },
    'beginletter-x': {
      id: 'd511ca88-ed19-4137-b6b9-9701e5bac6ae',
      label: 'X',
      type: 'letter',
    },
    'beginletter-y': {
      id: 'd4026e3c-44b3-4b4d-a3b5-8934bdec6fcc',
      label: 'Y',
      type: 'letter',
    },
    'beginletter-z': {
      id: 'de58b185-be1c-489b-a2f1-93d666b1d921',
      label: 'Z',
      type: 'letter',
    },
    'jongensnamen': {
      id: '75df74a4-ac09-47f0-a1ce-95affd800a6d',
      label: 'Jongensnamen',
      type: 'sex',
    },
    'meisjesnamen': {
      id: '53a5e5db-d3a5-4e00-adb2-e13c52420533',
      label: 'Meisjesnamen',
      type: 'sex',
    },
    'populaire-jongensnamen': {
      id: '574b0eb0-cbea-4f92-b660-ce9b4ce27798',
      label: 'Populaire jongensnamen',
      type: 'sex',
    },
    'populaire-meisjesnamen': {
      id: '94a78f8f-2e9b-47a4-ace0-83b3f020e5c7',
      label: 'Populaire meisjesnamen',
      type: 'sex',
    },
    'grieks': {
      id: '577327f4-e164-4b2c-a996-746fb76bd08c',
      label: 'Grieks',
      type: 'origin',
    },
    'hebreeuws': {
      id: '4612642b-2d81-4dd8-8809-d4b83fa7a865',
      label: 'Hebreeuws',
      type: 'origin',
    },
    'nieuwe-testament': {
      id: '014e9e9e-b0d1-42ff-9da1-e9edeba2c17f',
      label: 'Nieuwe testament',
      type: 'origin',
    },
    'oude-testament': {
      id: '62c4f332-edc2-429e-ad6d-326414467277',
      label: 'Oude testament',
      type: 'origin',
    },
  },
}
