import loglevel from 'loglevel'

const options = JSON.parse('{"debug":false,"disable":false,"logLevel":"silent","namespace":"preprcapture","preprAccessToken":"e942c3ac8072ceaa1208872391520f957920f7647fc26f1b51e025d58adfaf1a"}')
const { logLevel, namespace } = options
const log = loglevel.getLogger('preprcapture-plugin-client')
log.setLevel(logLevel)

/**
 * Create the plugin
 *
 * export default function (ctx, inject) {
 * https://nuxtjs.org/docs/2.x/directory-structure/plugins#inject-in-root--context
 *
 * get router info and store from context
 */
export default ({ app: { router }, store }) => {
  /**
   * Router setup
   */
  let isRouterReady = false

  router.onReady(() => {
    log.debug(`[${namespace}] router.onReady called`)
    // Mark when the router has completed the initial navigation.
    isRouterReady = true
  })

  /**
   * CCM setup
   */
  let unwatch = () => { }

  /**
   *
   */
  function preprTracking() {
    try {
      /**
       * prepr extracts the id from the metadata, we should wait until that's (re)set.
       */
      setTimeout(function () {
        window.prepr('event', 'View')
      }, 1000)
    } catch (e) {
      log.warn('Prepr event view timeout failed', e)
    }
  }

  /**
   * Start the tracking
   * The Nuxt way is to use router.afterEach
   * NOTE router.afterEach is called *before* DOM is updated.
   * So for serverside rendering, the first page must use window location
   */
  function startTracking () {
    log.info(`[${namespace}] Start tracking`)
    /**
     * Track server rendered page
     */
    if (isRouterReady) {
      preprTracking()
    }

    /**
     * Track all client rendered pages
     */
    router.afterEach(preprTracking)
  }

  /**
   * Initialize tracking
   */
  function init () {
    log.info(`[${namespace}] Initialize tracking`)

    /**
     * Turn off Nuxt watcher for Vuex store changes
     */
    unwatch()

    // If the code is initialized we can send an event to prepr:
    if (window.prepr) {
      log.debug(`[${namespace}] window.prepr exists`)
      startTracking()
    } else {
      log.debug(`[${namespace}] window.prepr not loaded yet`)
      window.prepr.onTrackerLoad = startTracking
    }
  }

  // Wait 2.5 seconds before initializing, so the application itself can be rendered first
  setTimeout(function () {
    /**
     * Check if permissions are set for analytics
     */
    if (store.state.cookieConsent.analytics) {
      log.info(`[${namespace}] Has consent`)
      init()
    } else {
      /**
       * If CCM wasn't fired before then set a watcher for when it does.
       * Set the unwatch function
       */
      unwatch = store.watch(
        state => state.cookieConsent.analytics,
        (analytics) => {
          log.info(`[${namespace}] Store permissions changed`, analytics)

          if (analytics) {
            init()
          }
        },
        { immediate: true },
      )
      log.info(`[${namespace}] Start watching for consent`, store.state.cookieConsent)
    }
  }, 2500)
}
