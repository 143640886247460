export const state = () => ({
  isLoading: false,
})

export const getters = {
  isLoading: (state) => { return state.isLoading },
}

export const mutations = {
  SET_LOADING: (state, payload) => {
    state.isLoading = payload
  },
}

export const actions = {
  setLoading ({ commit }, isLoading) {
    commit('SET_LOADING', isLoading)
  },
}
