import objectMapper from 'object-mapper'
import { programCompact } from './program'
import { topicCompact } from './topic'

export const pageCompact = data => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
  }

  return objectMapper(data, config)
}

export const page = (data) => {
  const config = {
    'id': 'id',
    'changed_on': 'changedOn',
    'publish_on.nl-NL': 'publishOn',
    'slug.nl-NL': 'slug',
    'read_time.nl-NL': 'readTime',
    'model.id': 'modelId',
    'model.body': 'modelName',
    'environment.id': 'environmentId',
    'items.nl-NL.title.body': 'title',
    'items.nl-NL.intro.body': 'intro',
    'items.nl-NL.hero.items[0].cdn_files.items[0].url': {
      key: 'hero',
      transform: url => url && url.replace('.png', '.jpg'),
    },
    'items.nl-NL.featured_video.items[]': 'featuredVideo[]',
    'items.nl-NL.content.items[]': 'content[]',
    'items.nl-NL.seo_description.body': 'seo.description',
    'items.nl-NL.seo_title.body': 'seo.title',
    'items.nl-NL.social_description.body': 'social.description',
    'items.nl-NL.social_title.body': 'social.title',
    'items.nl-NL.social_image.items[0].cdn_files.items[0].url': 'social.image',
    'items.nl-NL.topic.items[0]': {
      key: 'topic',
      transform: p => p && topicCompact(p),
    },
    'items.nl-NL.program.items[0]': {
      key: 'program',
      transform: p => p && programCompact(p),
    },
  }

  return objectMapper(data, config)
}
