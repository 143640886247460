export const EXTERNAL_LINKS = Object.freeze({
  EO_HOME: process.env.BASE_URL,
  EO_CORPORATE: process.env.BASE_URL_EO_CORPORATE,
  EO_SHOP: 'https://meer.eo.nl/bestel/word-eo-lid',
  NPO_START: 'https://www.npostart.nl/',
  EERST_DIT: process.env.BASE_URL_EERST_DIT,
})

export const radioPrograms = Object.freeze({
  RAD1: 'https://www.nporadio1.nl/gemist',
  RAD2: 'https://www.nporadio2.nl/uitzendinggemist',
  RAD3: 'https://www.npo3fm.nl/programmas/uitzendingen',
  RAD4: 'https://www.nporadio4.nl/gids-gemist',
  RAD5: 'https://www.nporadio5.nl/uitzendinggemist',
})

export const shareLinks = Object.freeze({
  API_WHATSAPP_SHARE: 'https://api.whatsapp.com/send?text=',
  API_WHATSAPP_CHAT: 'https://api.whatsapp.com/send?phone=31651254981',
})
