export const sort = {
  alphabetically: (array, propertyToSortBy) => {
    /**
     * Sort array of objects alphabetically
     * @param {array} array - an array of objects
     * @param {string} propertyToSortBy - the property name to sort by
     * @returns {string}
     */
    return array.sort((a, b) => {
      if (a[propertyToSortBy]) {
        return a[propertyToSortBy]?.toUpperCase().localeCompare(b[propertyToSortBy]?.toUpperCase())
      } else if (a[0]) {
        return a[0]?.toUpperCase().localeCompare(b[0]?.toUpperCase())
      } else if (typeof a === 'string') {
        return a.localeCompare(b)
      }
      return 0
    })
  },
}
