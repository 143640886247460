
import { HEADER_MENU_PRIMARY_ITEMS, HEADER_MENU_SECONDARY_ITEMS, HEADER_MENU_SOCIAL_ITEMS, HEADER_THEME_TABS } from '~/constants'

export default {
  data () {
    return {
      headerMenuPrimaryItems: HEADER_MENU_PRIMARY_ITEMS,
      headerMenuSecondaryItems: HEADER_MENU_SECONDARY_ITEMS,
      headerMenuSocialItems: HEADER_MENU_SOCIAL_ITEMS,
      headerThemeTabs: HEADER_THEME_TABS,
      keyword: null,
      isMounted: false,
      isMenuOpen: false,
      activeTab: 0,
      broadcasterLogo: this.headerBroadcasterLogo,
      collapseToggleText: {
        collapsed: "Meer thema's",
        expanded: "Minder thema's",
      },
      description: '',
    }
  },
  async fetch() {
    const {
      data: {
        items: [ page ],
      } = {},
    } = await this.$api.pages.getOneBySlug(this.$route.params.page, {
      domain: this.$config.preprEnvironmentDomainEva,
    })

    if (page) {
      this.description = page.seo?.description || page.intro
    }
  },
  head: {
    title: 'Home',
    titleTemplate: '%s - EO Eva',
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs() {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    closeMenu () {
      this.isMenuOpen = false
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    onNavigate () {
      this.isMenuOpen = false
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
